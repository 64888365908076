import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Modal, notification, Upload } from 'antd';
import { UploadOutlined, FileSyncOutlined } from '@ant-design/icons';
import { withFormErrors } from 'utils/common';
import { useToggle } from 'hooks/useToggle';
import { useFileUpload } from 'hooks/useFileUpload';
import { importSerialTournaments, selectSerialTournamentsLoading } from 'store/serial-tournaments';

export const ImportSerialModal = () => {
  const dispatch = useDispatch();
  const { fileList, handleChange, setStatus } = useFileUpload();
  const { active, activate, deactivate } = useToggle();
  const [form] = Form.useForm();
  const loading = useSelector(selectSerialTournamentsLoading);

  const onSubmit = useCallback(
    async (values) => {
      const formData = new FormData();
      formData.append('csvFile', values.file.file, values.file.file.name);

      return withFormErrors(form, async () => {
        const count = await dispatch(importSerialTournaments(formData));
        deactivate();
        form.resetFields();
        setStatus('success');
        notification.success({ message: `Successfully imported ${count} tournaments` });
      });
    },
    [form, dispatch, deactivate],
  );

  return (
    <>
      <Button type="primary" onClick={activate} icon={<FileSyncOutlined />}>
        Import Serial
      </Button>
      <Modal
        confirmLoading={loading}
        open={active}
        title="Import serial tournaments"
        onOk={form.submit}
        onCancel={deactivate}
      >
        <Form name="import-tournaments" form={form} layout="vertical" onFinish={onSubmit}>
          <Form.Item label="CSV file" name="file" rules={[{ required: true }]}>
            <Upload
              accept=".csv"
              maxCount={1}
              onChange={handleChange}
              fileList={fileList}
              beforeUpload={() => false}
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>Select file</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
