import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Tabs } from 'antd';
import { useTableParams } from 'hooks/useTableParams';
import { getTransferConfig, removeTransferConfig, selectIsLoading, selectTransferConfig } from 'store/transfer-config';
import CreateConfigModal from './fragments/CreateConfigModal';
import List from './fragments/List';
import Grid from './fragments/Grid';

const AccountRegions = () => {
  const dispatch = useDispatch();
  const { addFilter, params, nextPage } = useTableParams({ pagination: { page: 1, pageSize: 20 } });
  const isLoading = useSelector(selectIsLoading);
  const data = useSelector(selectTransferConfig);

  useEffect(() => {
    dispatch(getTransferConfig(params));
  }, [dispatch, params]);

  const fetch = useCallback(() => {
    dispatch(getTransferConfig());
  }, [dispatch]);

  const remove = useCallback(
    async (id) => {
      await dispatch(removeTransferConfig(id));
      dispatch(getTransferConfig(params));
    },
    [dispatch, params],
  );

  const tabs = useMemo(
    () => [
      {
        key: '1',
        label: 'List',
        children: (
          <List
            dataSource={data.docs}
            isLoading={isLoading}
            onAddFilter={addFilter}
            onNextPage={nextPage}
            onRemove={remove}
            onUpdate={fetch}
          />
        ),
      },
      {
        key: '2',
        label: 'Grid',
        children: <Grid />,
      },
    ],
    [data.docs, isLoading, addFilter, nextPage, remove, fetch],
  );

  return (
    <>
      <CreateConfigModal onClose={fetch} />
      <Divider />
      <Tabs defaultActiveKey="1" items={tabs} />
    </>
  );
};

export default AccountRegions;
