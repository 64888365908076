import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Col, Form, Input, Modal, Row, Select } from 'antd';
import { internalPurposes } from 'config/transactions';
import { mapFormErrors } from 'utils/format';
import { amount } from 'utils/validators';
import { useToggle } from 'hooks/useToggle';
import { createFakePersonalTransaction, selectLoading } from 'store/personal-transactions';
import { UserSelect } from 'components/Form/UserSelect';
import { CurrencySelect } from 'components/Form/CurrencySelect';
import InputNumber from 'components/Form/InputNumber';

const CURRENCIES = ['USD', 'EUR'];

const INITIAL_VALUES = { currency: 'USD' };

const CreateTransactionModal = ({ onCreate }) => {
  const dispatch = useDispatch();
  const { active, activate, deactivate } = useToggle();
  const [form] = Form.useForm();
  const loading = useSelector(selectLoading);

  const onFinish = useCallback(
    async (values) => {
      try {
        await dispatch(createFakePersonalTransaction(values));
        await onCreate();
        deactivate();
        form.resetFields();
      } catch (e) {
        if (e.response?.data?.errors) {
          form.setFields(mapFormErrors(e));
        }
      }
    },
    [form, dispatch, deactivate, onCreate],
  );

  return (
    <>
      <Button type="primary" onClick={activate}>
        Create transaction
      </Button>
      <Modal
        title="Create personal transaction"
        open={active}
        onOk={form.submit}
        confirmLoading={loading}
        onCancel={deactivate}
      >
        <Form form={form} onFinish={onFinish} initialValues={INITIAL_VALUES} layout="vertical">
          <Row justify="space-between">
            <Col span={16}>
              <Form.Item label="User" name="user" rules={[{ required: true }]}>
                <UserSelect />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Amount" name="amount" rules={[{ required: true }, amount]}>
                <InputNumber
                  addonAfter={
                    <Form.Item noStyle name="currency" rules={[{ required: true }]}>
                      <CurrencySelect currencies={CURRENCIES} />
                    </Form.Item>
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Purpose" name="purpose" rules={[{ required: true }]}>
            <Select options={internalPurposes} virtual={false} />
          </Form.Item>
          <Form.Item label="Comment" name="comment" style={{ marginBottom: 0 }} rules={[{ required: true }]}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item style={{ textAlign: 'right', marginBottom: 0 }} name="hiddenComment" valuePropName="checked">
            <Checkbox>Hidden</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateTransactionModal;
