import { Card, Col, Table } from 'antd';
import { useSelector } from 'react-redux';
import { selectList } from 'store/accounts';
import { toOptional } from 'utils/format';
import EditRegionModal from './EditRegionModal';

const CONFIGURABLE_ACCOUNTS = ['Skrill', 'Neteller'];

const columns = [
  {
    title: 'Account',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Region',
    dataIndex: 'region',
    key: 'region',
    render: toOptional,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: toOptional,
  },
  {
    title: '',
    key: 'actions',
    dataIndex: '_id',
    render: (_, account) => <EditRegionModal account={account} />,
  },
];

const AccountRegions = () => {
  const { list: accounts, loading } = useSelector(selectList);

  const dataSource = accounts.filter((account) => CONFIGURABLE_ACCOUNTS.includes(account.type));

  return (
    dataSource.length > 0 && (
      <Col span={16}>
        <Card title="Account regions">
          <Table loading={loading} rowKey="_id" columns={columns} dataSource={dataSource} pagination={false} />
        </Card>
      </Col>
    )
  );
};

export default AccountRegions;
