import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SmartTable from 'components/Table/SmartTable';
import { useTableParams } from 'hooks/useTableParams';
import { toDate } from 'utils/format';
import { selectNotifications, getMy } from 'store/notifications';
import ViewNotificationModal from './fragments/ViewNotificationModal';

const Notifications = () => {
  const dispatch = useDispatch();
  const { params, nextPage } = useTableParams();
  const notifications = useSelector(selectNotifications);
  const { t } = useTranslation();

  const fetchNotifications = useCallback(() => dispatch(getMy(params)), [dispatch, params]);

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  const columns = useMemo(
    () => [
      { key: 'title', dataIndex: 'title', title: t('notifications.title') },
      { key: 'createdAt', dataIndex: 'createdAt', title: t('notifications.createdAt'), render: toDate },
      { key: 'actions', title: '', render: (_, notification) => <ViewNotificationModal notification={notification} /> },
    ],
    [t],
  );

  return (
    <SmartTable
      rowKey="_id"
      columns={columns}
      dataSource={notifications.docs}
      scroll={{ y: 'calc(100vh - 88px)' }}
      infinity
      onFetch={nextPage}
    />
  );
};

export default Notifications;
