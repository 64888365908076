import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Divider, Image, Row, Space, Tabs } from 'antd';
import { getAll, selectList } from 'store/totals';
import { getAll as getAllAbi, selectList as selectAbiList } from 'store/totals-abi';
import { getAll as getGridGraphics, selectList as selectGridGraphics } from 'store/grid-graphics';
import { getAll as getRadarGraphics, selectList as selectRadarGraphics } from 'store/radar-graphics';
import { ImportTotalsModal } from 'components/Admin/Totals/ImportTotalsModal';
import { CommonTotalTable } from 'components/Totals/CommonTotalTable';
import { TotalABITable } from 'components/Totals/TotalABITable';
import { ImportGraphicsModal } from 'components/Admin/Totals/ImportGrahicsModal';
import { UserSelect } from 'components/Form/UserSelect';
import { ImportImagesModal } from 'components/Admin/Totals/ImportImagesModal';

export const Totals = () => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({});
  const { list, loading } = useSelector(selectList);
  const { list: listAbi, loading: loadingAbi } = useSelector(selectAbiList);
  const { list: listGridGraphics, loading: loadingGridGraphics } = useSelector(selectGridGraphics);
  const { list: listRadarGraphics, loading: loadingRadarGraphics } = useSelector(selectRadarGraphics);
  const { t } = useTranslation();

  const onPlayerNameChange = useCallback((current) => {
    updateFilter({ playerName: current });
  }, []);

  const updateFilter = useCallback((payload) => {
    setFilter((current) => ({ ...current, ...payload }));
  }, []);

  useEffect(() => {
    if (filter.playerName) {
      dispatch(getAll(filter));
      dispatch(getAllAbi(filter));
      dispatch(getGridGraphics(filter));
      dispatch(getRadarGraphics(filter));
    }
  }, [filter]);

  return (
    <>
      <Space>
        <ImportTotalsModal />
        <ImportGraphicsModal />
        <UserSelect allowClear onChange={onPlayerNameChange} by="username" />
      </Space>
      <Space style={{ float: 'right' }}>
        <ImportImagesModal />
      </Space>
      <Divider />
      <Tabs type="card" defaultActiveKey="totals">
        {list?.length > 0 && (
          <Tabs.TabPane tab={t('totals.tabs.totals')} key="totals">
            <Row justify="space-between" gutter={10}>
              <Col span={11}>
                <CommonTotalTable dataSource={list} loading={loading} />
              </Col>
              <Col span={13}>
                <TotalABITable dataSource={listAbi} loading={loadingAbi} />
              </Col>
            </Row>
          </Tabs.TabPane>
        )}
        {listGridGraphics?.length > 0 && (
          <Tabs.TabPane tab={t('totals.tabs.grid')} key="grid">
            <Image src={listGridGraphics[0].url} preview={false} loading={loadingGridGraphics} />
          </Tabs.TabPane>
        )}
        {listRadarGraphics?.length > 0 && (
          <Tabs.TabPane tab={t('totals.tabs.radar')} key="radar">
            <Image src={listRadarGraphics[0].url} preview={false} loading={loadingRadarGraphics} />
          </Tabs.TabPane>
        )}
      </Tabs>
    </>
  );
};
