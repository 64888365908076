import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Input, InputNumber, Modal, Row, Select } from 'antd';
import { amount } from 'utils/validators';
import { toCurrency } from 'utils/format';
import { useToggle } from 'hooks/useToggle';
import {
  createExternalTransaction,
  getAllExternalTransactions,
  selectExternalTransactions,
} from 'store/external-transactions';
import { getAllPaymentNames } from 'store/payments';

const required = [{ required: true }];

const TransactionModal = () => {
  const dispatch = useDispatch();
  const [transactionForm] = Form.useForm();
  const { active, activate, deactivate } = useToggle();
  const { loading } = useSelector(selectExternalTransactions);
  const [paymentNames, setPaymentNames] = useState([]);
  const [activeCurrency, setActiveCurrency] = useState('');

  const getAllNames = useCallback(async () => {
    const data = await dispatch(getAllPaymentNames());
    setPaymentNames(data);
  }, [dispatch]);

  useEffect(() => {
    if (active) {
      getAllNames();
    }
  }, [getAllNames, active]);

  const createTransaction = useCallback(
    async (values) => {
      await dispatch(createExternalTransaction(values));
      deactivate();
      transactionForm.resetFields();
      dispatch(getAllExternalTransactions());
    },
    [dispatch, transactionForm, deactivate],
  );

  const onNameChange = useCallback(
    (value) => {
      paymentNames?.forEach((payment) => {
        if (payment.name === value) {
          setActiveCurrency(payment.currency);
          transactionForm.setFieldsValue({
            currency: payment.currency,
          });
        }
      });
    },
    [paymentNames, transactionForm],
  );

  return (
    <>
      <Button type="primary" onClick={activate}>
        Add Transaction
      </Button>
      <Modal
        title="Add transaction"
        open={active}
        onOk={transactionForm.submit}
        confirmLoading={loading}
        onCancel={deactivate}
      >
        <Form form={transactionForm} onFinish={createTransaction} layout="vertical">
          <Row gutter={10}>
            <Col span={14}>
              <Form.Item label="Name" name="name" rules={required}>
                <Select placeholder="Name" onChange={onNameChange} showSearch virtual={false}>
                  {paymentNames?.map((payment) => (
                    <Select.Option key={payment.name}>{payment.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label="Amount" name="amount" rules={[{ required: true }, amount]}>
                <InputNumber
                  controls={false}
                  precision={activeCurrency === 'BTC' ? 5 : 2}
                  parser={(value) => value.replace(',', '.')}
                  addonAfter={toCurrency(activeCurrency)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Currency" name="currency" rules={required} hidden />
          <Form.Item label="Comment" name="comment" rules={required} style={{ marginBottom: 0 }}>
            <Input.TextArea rows={4} placeholder="Comment" maxLength={400} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default TransactionModal;
