import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Modal, Row, Typography, Divider, message, Statistic } from 'antd';
import { useToggle } from 'hooks/useToggle';
import { donate, release, reserve, selectLoading } from 'store/transfers';
import { getMyInternal } from 'store/internal-transactions';
import { toCurrency } from 'utils/format';
import { withFormErrors } from 'utils/common';
import { natural } from 'utils/validators';
import { ImageUpload } from 'components/Form/ImageUpload';
import InputNumber from 'components/Form/InputNumber';

export const DonateModal = ({ request, disabled }) => {
  const dispatch = useDispatch();
  const { active, activate, deactivate } = useToggle();
  const [available, setAvailable] = useState(true);
  const [deadline, setDeadline] = useState(0);
  const loading = useSelector(selectLoading);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const open = useCallback(async () => {
    try {
      const data = await dispatch(reserve(request._id));

      if (data?.expires) {
        setDeadline(new Date(data.expires).getTime());
        activate();
      }
    } catch (e) {}
  }, [dispatch, request, activate]);

  const close = useCallback(async () => {
    deactivate();
    form.resetFields();
    try {
      await dispatch(release(request._id));
    } catch (e) {
      message.error(e.message);
    }
  }, [request, deactivate, form, dispatch]);

  useEffect(() => {
    const now = Date.now();
    if (request.reserve?.expires > now) {
      setAvailable(false);
      const timer = setTimeout(() => {
        setAvailable(true);
      }, request.reserve.expires - now);

      return () => clearTimeout(timer);
    }
  }, [request]);

  useEffect(() => {
    if (deadline) {
      const timer = setTimeout(() => {
        deactivate();
        message.error(t('transfers.time_expired'));
      }, deadline - Date.now());

      return () => clearTimeout(timer);
    }
  }, [deadline, deactivate, t]);

  const onFinish = useCallback(
    (values) => {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        if (key === 'screenshot' && values[key]) {
          formData.append(key, values[key], values[key].name);
        } else {
          formData.append(key, values[key] || 0);
        }
      });
      withFormErrors(form, async () => {
        await dispatch(donate(request._id, formData));
        deactivate();
        form.resetFields();
        dispatch(getMyInternal());
      });
    },
    [request, dispatch, form, deactivate],
  );

  const currency = toCurrency(request.account.currency);

  return (
    <>
      {disabled && !available ? (
        <Statistic.Countdown value={request?.reserve?.expires} format="mm:ss" />
      ) : (
        <Button type="primary" loading={loading} onClick={open}>
          {t('send')}
        </Button>
      )}
      <Modal
        title={<Statistic.Countdown value={deadline} format="mm:ss" />}
        open={active}
        onOk={form.submit}
        cancelText={t('cancel')}
        confirmLoading={loading}
        onCancel={close}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          {request.transferAddress && (
            <>
              <div>
                {t('transfer.transfer_address')}:{' '}
                <Typography.Text copyable={{ tooltips: [t('copy'), t('copied')] }} underline>
                  {request.transferAddress}
                </Typography.Text>
              </div>
              <Divider />
            </>
          )}
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item label={t('amount')} name="amount" rules={[{ required: true }, natural]}>
                <InputNumber
                  max={request.requestedAmount - request.currentAmount}
                  addonAfter={currency}
                  placeholder={`${t('transfers.max_amount')}: ${(
                    request.requestedAmount - request.currentAmount
                  ).toFixed(2)}`}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={t('transfers.commission')} name="commission">
                <InputNumber min={0} addonAfter={currency} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label={t('placeholder.screenshot')} name="screenshot" style={{ marginBottom: 0 }}>
            <ImageUpload />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
