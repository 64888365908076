import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Select, Space } from 'antd';
import debounce from 'lodash.debounce';
import { ROOMS, ROOMS_MAP } from 'config/tournaments';
import DatePicker from 'components/Form/DatePicker';
import InputNumber from 'components/Form/InputNumber';
import { disableEndDate, disableStartDate } from './utils';
import { MY_ROOMS, TYPES } from './config';

const TournamentsSearch = ({ myRooms = [], onChange, extra }) => {
  const { t } = useTranslation();
  const [rooms, setRooms] = useState(undefined);
  const [initialized, setInitialized] = useState(false);

  const handleRoomChange = useCallback(
    (values) => {
      const cb = onChange('room');
      setRooms(values);
      if (values.includes(MY_ROOMS)) {
        cb([...myRooms.map((room) => ROOMS_MAP[room] || room), ...values.filter((room) => room !== MY_ROOMS)]);
      } else {
        cb(values);
      }
    },
    [onChange, myRooms],
  );

  useEffect(() => {
    if (!initialized && myRooms.length) {
      onChange('room')(myRooms.map((room) => ROOMS_MAP[room] || room));
      setRooms([MY_ROOMS]);
      setInitialized(true);
    }
  }, [onChange, myRooms, initialized]);

  return (
    <Space style={{ marginBottom: 24 }}>
      <DatePicker
        showTime
        disabledDate={disableStartDate}
        format="DD.MM.YYYY HH:mm"
        placeholder={t('placeholder.dateFrom')}
        onChange={onChange('dateFrom')}
      />
      <DatePicker
        showTime
        disabledDate={disableEndDate}
        format="DD.MM.YYYY HH:mm"
        placeholder={t('placeholder.dateTo')}
        onChange={onChange('dateTo')}
      />
      <Select
        mode="multiple"
        allowClear
        options={(myRooms.length ? [MY_ROOMS, ...ROOMS] : ROOMS).map((room) => ({
          value: room,
          label: t(room),
        }))}
        onChange={handleRoomChange}
        value={rooms}
        placeholder={t('placeholder.room')}
        style={{ minWidth: 150 }}
        virtual={false}
      />
      <Input.Group compact>
        <InputNumber placeholder={t('placeholder.buy_in_from')} onChange={debounce(onChange('buyInFrom'), 500)} />
        <InputNumber placeholder={t('placeholder.buy_in_to')} onChange={debounce(onChange('buyInTo'), 500)} />
      </Input.Group>
      <Select
        mode="multiple"
        allowClear
        options={TYPES.map(({ value }) => ({ value, label: value }))}
        placeholder={t('placeholder.type')}
        onChange={onChange('type')}
        style={{ minWidth: 100 }}
        virtual={false}
      />
      {extra}
    </Space>
  );
};

export default TournamentsSearch;
