import { useCallback, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { uploadImage } from 'store/images';
import { selectMyActivePackage } from 'store/packages';
import { useTranslation } from 'react-i18next';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const UploadScreenshot = ({ account }) => {
  const dispatch = useDispatch();
  const { myActive } = useSelector(selectMyActivePackage);
  const { t } = useTranslation();

  const [fileList, setFileList] = useState([]);

  const handleChange = useCallback(({ fileList: newFileList }) => setFileList(newFileList), []);

  const customRequest = useCallback(
    async ({ file, onSuccess, onError }) => {
      const data = new FormData();
      data.append('image', file, file.name);
      data.append('accountId', account._id);
      data.append('type', account.type);
      if (account.groupId) {
        data.append('groupId', account.groupId);
      }
      data.append('currency', account.currency);
      data.append('packageId', myActive._id);

      try {
        const res = await dispatch(uploadImage(data));
        // noinspection JSUnresolvedReference
        handleChange({ fileList: [res] });
        onSuccess(data, file);
      } catch (e) {
        onError(e);
      }
    },
    [dispatch, myActive, account],
  );

  const handlePreview = useCallback(async (file) => {
    if (!file.url) {
      file.url = await getBase64(file.originFileObj);
    }

    window.open(file.url, '_blank');
  }, []);

  return (
    <Upload
      accept="image/*"
      listType="picture-card"
      customRequest={customRequest}
      fileList={fileList}
      onPreview={handlePreview}
      onChange={handleChange}
    >
      {!fileList.length && (
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>{t('upload')}</div>
        </div>
      )}
    </Upload>
  );
};

export default UploadScreenshot;
