import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Modal, Row, Select } from 'antd';
import { getName } from 'utils/account';
import { withFormErrors } from 'utils/common';
import { toCurrency } from 'utils/format';
import { natural } from 'utils/validators';
import { useToggle } from 'hooks/useToggle';
import { createMy, getMyAvailableAccounts, selectLoading } from 'store/transfers';
import InputNumber from 'components/Form/InputNumber';

const CreateTransferRequestModal = () => {
  const dispatch = useDispatch();
  const { active, activate, deactivate } = useToggle();
  const [accounts, setAccounts] = useState([]);
  const [accountsLoading, setAccountsLoading] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const loading = useSelector(selectLoading);
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    if (active) {
      setAccountsLoading(true);
      // noinspection JSUnresolvedReference
      dispatch(getMyAvailableAccounts({ ableToTransfer: true })).then(({ data }) => {
        setAccounts(data);
        setAccountsLoading(false);
      });
    }
  }, [active, dispatch]);

  const onClose = useCallback(() => {
    deactivate();
    form.resetFields();
  }, [form, deactivate]);

  const onFinish = useCallback(
    (values) =>
      withFormErrors(form, async () => {
        await dispatch(createMy({ ...values, transferAddress: selectedAccount.transferAddress }));
        onClose();
      }),
    [dispatch, onClose, selectedAccount],
  );

  const onAccountChange = useCallback((id) => setSelectedAccount(accounts.find((acc) => acc._id === id)), [accounts]);

  return (
    <>
      <Button type="primary" onClick={activate}>
        {t('transfers.create_request')}
      </Button>
      <Modal
        title={t('transfers.create_request')}
        open={active}
        onOk={form.submit}
        cancelText={t('cancel')}
        onCancel={onClose}
        confirmLoading={loading}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Row justify="space-between" gutter={10} style={{ marginBottom: -24 }}>
            <Col span={16}>
              <Form.Item label={t('account')} name="account" rules={[{ required: true }]}>
                <Select virtual={false} loading={accountsLoading} onChange={onAccountChange}>
                  {accounts?.map((account) => (
                    <Select.Option key={account._id}>{getName(account)}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={t('amount')} name="amount" rules={[{ type: 'number', required: true }, natural]}>
                <InputNumber addonAfter={toCurrency(selectedAccount?.currency)} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CreateTransferRequestModal;
