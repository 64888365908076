import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Form, Input, Modal, Row, Switch } from 'antd';
import { positive } from 'utils/validators';
import useLoading from 'hooks/useLoading';
import { update, getAll } from 'store/notifications';
import { useModal } from 'components/Modal';
import Editor from 'components/Form/Editor';
import InputNumber from 'components/Form/InputNumber';

export const MODAL_ID = 'EDIT_NOTIFICATION_MODAL';

const EditNotificationModal = () => {
  const dispatch = useDispatch();
  const {
    payload: { notification },
    deactivate,
  } = useModal();
  const { execute, loading } = useLoading();
  const [form] = Form.useForm();
  const [withFilter, setWithFilter] = useState(false);
  const [minCourse, setMinCourse] = useState(0);

  const updateOne = useCallback(
    async (values) => {
      try {
        await execute(() =>
          dispatch(
            update(notification._id, {
              title: values.title,
              description: values.description,
              filter: values.course?.min || values.course?.max ? { course: values.course } : undefined,
            }),
          ),
        );
        await dispatch(getAll());
        deactivate();
      } catch (e) {
        console.error(e);
      }
    },
    [execute, dispatch, notification, deactivate],
  );

  const initialValues = useMemo(
    () => ({
      title: notification.title,
      description: notification.description,
      course: notification.filter?.course,
    }),
    [notification],
  );

  return (
    <Modal
      title="Edit Notification"
      open
      width={1000}
      onCancel={deactivate}
      onOk={form.submit}
      okText="Update"
      confirmLoading={loading}
    >
      <Form layout="vertical" form={form} onFinish={updateOne} initialValues={initialValues}>
        <Row gutter={10}>
          <Col style={{ flex: 1 }}>
            <Form.Item label="Title" name="title" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Course">
              <Input.Group compact>
                <Form.Item
                  name={['course', 'min']}
                  noStyle
                  rules={[{ required: withFilter, message: 'Required' }, positive]}
                >
                  <InputNumber
                    min={0}
                    max={16}
                    addonBefore={<Switch checked={withFilter} onChange={setWithFilter} size="small" />}
                    style={{ width: 86 }}
                    onChange={setMinCourse}
                    disabled={!withFilter}
                  />
                </Form.Item>
                <Form.Item
                  name={['course', 'max']}
                  noStyle
                  rules={[{ required: withFilter, message: 'Required' }, positive]}
                >
                  <InputNumber min={minCourse} max={16} style={{ width: 35 }} disabled={!withFilter} />
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="Description" name="description" rules={[{ required: true }]}>
          <Editor />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditNotificationModal;
