import { apiCallBegan } from "./api";
import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const slice = createSlice({
  name: 'tools',
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {
    toolsRequestStarted: (tools) => {
      tools.loading = true;
    },
    toolsRequestFailed: (tools, action) => {
      tools.error = action.payload.data;
    },
    toolsRequestEnded: (tools) => {
      tools.loading = false;
    },
  },
});

export const { toolsRequestStarted, toolsRequestFailed, toolsRequestEnded } = slice.actions;

export default slice.reducer;

//api calls
export const hrcRequest = (req) =>
  apiCallBegan({
    url: '/tools/hrc',
    method: 'post',
    data: req,
    onStart: toolsRequestStarted,
    onEnd: toolsRequestEnded,
    onError: toolsRequestFailed,
  });

export const selectTools = createSelector(
  (state) => state.tools,
  (tools) => ({
    loading: tools.loading,
    }),
);


