export const ACCOUNT_REGIONS = {
  Skrill: {
    label: 'Skrill',
    statuses: ['Skriller', 'True', 'Silver', 'Gold+'],
  },
  Neteller: {
    label: 'Neteller',
    statuses: ['Standart', 'True', 'Silver', 'Gold+'],
  },
};
