import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import { Select } from 'antd';
import { searchUsers } from 'store/users';

export const UserSelect = ({ by = 'id', filter = {}, ...props }) => {
  const dispatch = useDispatch();
  const [usersList, setUsersList] = useState([]);

  const searchUser = useCallback(
    async (query) => {
      const data = await dispatch(searchUsers(query, filter));
      setUsersList(data?.map(({ _id, username }) => ({ value: by === 'id' ? _id : username, label: username })));
    },
    [dispatch, filter],
  );

  return (
    <Select
      showSearch
      placeholder="Type login..."
      onSearch={debounce(searchUser, 500)}
      filterOption={false}
      options={usersList}
      virtual={false}
      {...props}
    />
  );
};
