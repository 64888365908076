import { REQUEST_TYPE } from 'config/playing-myself';

export const shouldBeReported = (record) =>
  [REQUEST_TYPE.MYSELF, REQUEST_TYPE.FREEROLL].includes(record.status) && record.isReported !== undefined;

export const isReportTimeExpired = (record) =>
  Date.now() - new Date(record.closedAt).getTime() >
  process.env.REACT_APP_PLAYING_MYSELF_REPORT_TIME_AFTER_CLOSE * 1000;

export const isReportRevertTimeExpired = (record) =>
  Date.now() - new Date(record.closedAt).getTime() >
  process.env.REACT_APP_PLAYING_MYSELF_REPORT_TIME_AFTER_EXPIRATION * 1000;

export const isReportExpired = (record) =>
  shouldBeReported(record) && !record.isReported && isReportTimeExpired(record);

export const rowClassName = (record) => {
  if (shouldBeReported(record) && !record.isReported) {
    if (isReportTimeExpired(record)) {
      return 'red-row';
    }

    return 'yellow-row';
  }
};
