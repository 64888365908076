import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Modal, Row, Select } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { ACCOUNT_REGIONS } from 'config/accounts';
import { useToggle } from 'hooks/useToggle';
import { selectList as selectAccounts, updateRegion } from 'store/accounts';
import CountrySelect from 'components/CountrySelect';

const EditRegionModal = ({ account }) => {
  const dispatch = useDispatch();
  const { active, activate, deactivate } = useToggle();
  const { loading } = useSelector(selectAccounts);

  const [form] = Form.useForm();

  const close = useCallback(() => {
    form.resetFields();
    deactivate();
  }, [form.resetFields, deactivate]);

  const statusOptions = useMemo(
    () => ACCOUNT_REGIONS[account.type].statuses.map((status) => ({ value: status, label: status })),
    [account.type],
  );

  const handleSubmit = useCallback(
    async (values) => {
      await dispatch(updateRegion(account._id, values));
      close();
    },
    [dispatch, close],
  );

  return (
    <>
      <Button type="primary" icon={<EditOutlined />} onClick={activate} />
      <Modal open={active} title="Edit Region" onOk={form.submit} confirmLoading={loading} onCancel={close}>
        <Form
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          initialValues={{
            region: account.region,
            status: account.status,
          }}
        >
          <Row gutter={10} style={{ marginBottom: -24 }}>
            <Col span={12}>
              <Form.Item label="Region" name="region">
                <CountrySelect />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Status" name="status">
                <Select options={statusOptions} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default EditRegionModal;
