import { notification } from 'antd';
import { apiCallFailed } from '../api';
import { logoutSucceeded } from '../auth';

export const apiError =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    const blackListOfCodes = [422, 409, 403, 429];
    if (action.type !== apiCallFailed.type) return next(action);

    const error = action.payload;

    if (error.response) {
      if (error.response.status === 401) {
        dispatch(logoutSucceeded());
      } else if(error.response.headers['x-ratelimit-remaining']) {
        // do nothing
      } else if (!blackListOfCodes.includes(error.response.status)) {
        notification.error({
          duration: 2,
          message: `Error: ${error.response.status}`,
          description: error.response.data.message || error.response.data.error || error.message,
        });
      }
    }
  };

export default apiError;
