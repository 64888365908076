import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Form, Input, Modal, Row, Switch } from 'antd';
import { positive } from 'utils/validators';
import { useToggle } from 'hooks/useToggle';
import useLoading from 'hooks/useLoading';
import { create as createNotification, getAll } from 'store/notifications';
import Editor from 'components/Form/Editor';
import InputNumber from 'components/Form/InputNumber';
import { Status } from '../config';

const CreateNotificationModal = () => {
  const dispatch = useDispatch();
  const { active, activate, deactivate } = useToggle();
  const { execute, loading } = useLoading();
  const [form] = Form.useForm();
  const [withFilter, setWithFilter] = useState(false);
  const [minCourse, setMinCourse] = useState(0);

  const create = useCallback(
    async (values) => {
      try {
        await execute(() =>
          dispatch(
            createNotification({
              title: values.title,
              description: values.description,
              filter: values.course?.min || values.course?.max ? { course: values.course } : undefined,
              status: values.status || Status.Pending,
            }),
          ),
        );
        await dispatch(getAll());
        form.resetFields();
        deactivate();
      } catch (e) {
        console.error(e);
      }
    },
    [execute, dispatch, form, deactivate],
  );

  return (
    <>
      <Button type="primary" onClick={activate}>
        Create
      </Button>
      <Modal
        title="Create Notification"
        open={active}
        width={1000}
        onCancel={deactivate}
        onOk={form.submit}
        okText="Create"
        confirmLoading={loading}
      >
        <Form layout="vertical" form={form} onFinish={create}>
          <Row gutter={10}>
            <Col style={{ flex: 1 }}>
              <Form.Item label="Title" name="title" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item label="Course">
                <Input.Group compact>
                  <Form.Item
                    name={['course', 'min']}
                    noStyle
                    rules={[{ required: withFilter, message: 'Required' }, positive]}
                  >
                    <InputNumber
                      min={0}
                      max={16}
                      addonBefore={<Switch checked={withFilter} onChange={setWithFilter} size="small" />}
                      style={{ width: 86 }}
                      onChange={setMinCourse}
                      disabled={!withFilter}
                    />
                  </Form.Item>
                  <Form.Item
                    name={['course', 'max']}
                    noStyle
                    rules={[{ required: withFilter, message: 'Required' }, positive]}
                  >
                    <InputNumber min={minCourse} max={16} style={{ width: 35 }} disabled={!withFilter} />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Description" name="description" rules={[{ required: true }]}>
            <Editor />
          </Form.Item>
          <Form.Item name="status" noStyle>
            <Input type="hidden" value={Status.Pending} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CreateNotificationModal;
