import { apiCallBegan } from './api';
import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

const slice = createSlice({
  name: 'craft',
  initialState: {
    summary: {},
    list: {
      docs: [],
    },
    info: {},
    loading: false,
    error: null,
  },
  reducers: {
    craftRequestStarted: (craft) => {
      craft.loading = true;
    },
    craftRequestFailed: (craft, action) => {
      craft.error = action.payload.data;
    },
    craftRequestEnded: (craft) => {
      craft.loading = false;
    },
    craftReceived: (craft, action) => {
      craft.list = action.payload.data;
    },

    craftInfoReceived: (craft, action) => {
      craft.info = action.payload.data;
    },
    craftSummaryReceived: (craft, action) => {
      craft.summary = action.payload.data.length > 0 ? action.payload.data[0] : {};
    },
  },
});

export const {
  craftRequestStarted,
  craftRequestFailed,
  craftRequestEnded,
  craftReceived,
  craftSummaryReceived,
  craftInfoReceived,
} = slice.actions;

export default slice.reducer;

//api calls
export const importArchive = (data) =>
  apiCallBegan({
    url: `/craft/my/upload`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    data,
    onStart: craftRequestStarted,
    onEnd: craftRequestEnded,
  });

export const findAll = (params) =>
  apiCallBegan({
    url: `/craft`,
    params,
    onStart: craftRequestStarted,
    onSuccess: craftReceived,
    onError: craftRequestFailed,
    onEnd: craftRequestEnded,
  });

export const findAllMy = (params) =>
  apiCallBegan({
    url: `/craft/my`,
    params,
    onStart: craftRequestStarted,
    onSuccess: craftReceived,
    onError: craftRequestFailed,
    onEnd: craftRequestEnded,
  });

export const getMySummary = (params) =>
  apiCallBegan({
    url: `/craft/my/summary`,
    params,
    onStart: craftRequestStarted,
    onSuccess: craftSummaryReceived,
    onError: craftRequestFailed,
    onEnd: craftRequestEnded,
  });

export const getSummary = (params) =>
  apiCallBegan({
    url: `/craft/summary`,
    params,
    onStart: craftRequestStarted,
    onSuccess: craftSummaryReceived,
    onError: craftRequestFailed,
    onEnd: craftRequestEnded,
  });

export const getInfo = (params) =>
  apiCallBegan({
    url: `/craft/info`,
    params,
    onStart: craftRequestStarted,
    onSuccess: craftInfoReceived,
    onError: craftRequestFailed,
    onEnd: craftRequestEnded,
  });

export const exportCsv = (params) =>
  apiCallBegan({
    url: `/craft/export`,
    params,
    onStart: craftRequestStarted,
    onError: craftRequestFailed,
    onEnd: craftRequestEnded,
  });

export const getArchiveUrl = () =>
  apiCallBegan({
    url: `/craft/archive`,
    onStart: craftRequestStarted,
    onError: craftRequestFailed,
    onEnd: craftRequestEnded,
  });

export const refreshArchive = () =>
  apiCallBegan({
    url: `/craft/archive/refresh`,
    onStart: craftRequestStarted,
    onError: craftRequestFailed,
    onEnd: craftRequestEnded,
  });

export const selectCraft = createSelector(
  (state) => state.craft,
  (craft) => ({
    list: craft.list,
    info: craft.info,
    summary: craft.summary,
    loading: craft.loading,
  }),
);
