import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import memoize from 'lodash.memoize';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'auth',
  initialState: {
    authenticated: false,
    user: null,
    loading: false,
  },
  reducers: {
    authRequested: (auth) => {
      auth.loading = true;
    },
    authRequestEnded: (auth) => {
      auth.loading = false;
    },
    authSucceeded: (auth, action) => {
      auth.authenticated = true;
      auth.user = action.payload.data;
    },
    logoutSucceeded: (auth) => {
      auth.authenticated = false;
      auth.user = null;
      document.location.href = '/login';
    },
    passwordUpdated: (auth) => {
      auth.user.isPasswordChangeRequired = false;
    },
  },
});

export const { authRequested, authRequestEnded, authSucceeded, logoutSucceeded, passwordUpdated } = slice.actions;
export default slice.reducer;

//api calls
export const login = (credentials) =>
  apiCallBegan({
    url: '/auth/login',
    method: 'post',
    data: credentials,
    onStart: authRequested,
    onSuccess: authSucceeded,
    onEnd: authRequestEnded,
  });

export const logout = () =>
  apiCallBegan({
    url: '/auth/logout',
    method: 'post',
    onStart: authRequested,
    onSuccess: logoutSucceeded,
    onEnd: authRequestEnded,
  });

export const getMe = () =>
  apiCallBegan({
    url: '/users/me',
    onStart: authRequested,
    onSuccess: authSucceeded,
    onEnd: authRequestEnded,
  });

export const passwordUpdateUser = (id, data) =>
  apiCallBegan({
    url: `/users/${id}/update-password`,
    method: 'patch',
    data,
    onStart: authRequested,
    onSuccess: passwordUpdated,
    onEnd: authRequestEnded,
  });

//selector
export const selectAuth = createSelector(
  (state) => state.auth,
  (auth) => auth,
);

export const selectAuthenticated = createSelector(
  (state) => state.auth,
  (auth) => auth.authenticated,
);

export const selectUser = createSelector(
  (state) => state.auth,
  (auth) => auth.user,
);

export const is = createSelector(
  (state) => state.auth.user,
  (user) => memoize((role) => user?.roles.some(({ name }) => name === role)),
);

export const can = createSelector(
  (state) => state.auth.user,
  (user) => memoize((permission) => user?.permissions.some(({ name }) => name === permission)),
);
