import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, DatePicker, Form, Input, Select, Space, TimePicker } from 'antd';
import { WEEK_DAYS } from 'config/common';
import { rangeWithStep } from 'utils/generators';
import { getFieldValues } from 'store/serial-evaluation';
import InputNumber from 'components/Form/InputNumber';

export const SerialEvaluationBar = ({ onSearch, loading }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [seriesList, setSeriesList] = useState([]);
  const [reentryList, setReentryList] = useState([]);
  const [roomList, setRoomList] = useState([]);

  useEffect(async () => {
    const data = await dispatch(
      getFieldValues({
        reentry: 1,
      }),
    );
    if (Array.isArray(data)) {
      setReentryList(data);
    }
  }, [dispatch]);

  useEffect(async () => {
    const data = await dispatch(
      getFieldValues({
        room: 1,
      }),
    );
    if (Array.isArray(data)) {
      setRoomList(data);
    }
  }, [dispatch]);

  useEffect(async () => {
    const data = await dispatch(
      getFieldValues({
        series: 1,
      }),
    );
    if (Array.isArray(data)) {
      setSeriesList(data);
    }
  }, [dispatch]);

  const onSubmit = useCallback(
    (values) => {
      onSearch({
        ...values,
        dateFrom: values.dateFrom ? values.dateFrom.startOf('day').unix() * 1000 : null,
        dateTo: values.dateTo ? values.dateTo.endOf('day').unix() * 1000 : null,
        timeFrom: values.timeFrom ? values.timeFrom.unix() * 1000 : null,
        timeTo: values.timeTo ? values.timeTo.unix() * 1000 : null,
      });
    },
    [onSearch],
  );

  return (
    <Form onFinish={onSubmit} form={form} layout="horizontal">
      <Space wrap>
        <Form.Item name="dateFrom">
          <DatePicker format="DD.MM.YYYY" placeholder="Start date" />
        </Form.Item>
        <Form.Item name="dateTo">
          <DatePicker format="DD.MM.YYYY" placeholder="End date" />
        </Form.Item>
        <Form.Item name="weekDay">
          <Select
            showArrow
            mode="multiple"
            virtual={false}
            allowClear
            placeholder="Day"
            style={{ minWidth: 60 }}
            options={WEEK_DAYS.map((day, i) => ({ value: i + 1, label: day }))}
          />
        </Form.Item>
        <Form.Item name="timeFrom">
          <TimePicker format="HH:mm" placeholder="From" />
        </Form.Item>
        <Form.Item name="timeTo">
          <TimePicker format="HH:mm" placeholder="To" />
        </Form.Item>
        <Form.Item name="room">
          <Select
            showArrow
            mode="multiple"
            showSearch
            allowClear
            virtual={false}
            placeholder="Room"
            style={{ minWidth: 120 }}
            options={roomList.map((room) => ({ value: room, label: room }))}
          />
        </Form.Item>
        <Form.Item name="titleIncludes">
          <Input placeholder="Title includes" />
        </Form.Item>
        <Form.Item name="titleExcludes">
          <Input placeholder="Title excludes" />
        </Form.Item>
        <Form.Item name="buyInFrom">
          <InputNumber placeholder="Min BI" />
        </Form.Item>
        <Form.Item name="buyInTo">
          <InputNumber placeholder="Max BI" />
        </Form.Item>
        <Form.Item name="quality">
          <Select
            allowClear
            virtual={false}
            mode="multiple"
            placeholder="Quality"
            style={{ minWidth: 70 }}
            options={rangeWithStep(1, 4, 1).map((value) => ({ value, label: value }))}
          />
        </Form.Item>
        <Form.Item name="course">
          <Select
            virtual={false}
            allowClear
            mode="multiple"
            placeholder="Course"
            style={{ minWidth: 70 }}
            options={rangeWithStep(1, 16, 1).map((value) => ({ value, label: value }))}
          />
        </Form.Item>
        <Form.Item name="type">
          <Select
            allowClear
            virtual={false}
            mode="multiple"
            placeholder="Type"
            style={{ minWidth: 70 }}
            options={rangeWithStep(1, 8, 1).map((value) => ({ value, label: value }))}
          />
        </Form.Item>
        <Form.Item name="series">
          <Select
            mode="multiple"
            showSearch
            virtual={false}
            allowClear
            placeholder="Series"
            style={{ minWidth: 170 }}
            options={seriesList.map((label) => ({ value: label, label }))}
          />
        </Form.Item>
        <Form.Item name="reentry">
          <Select
            allowClear
            virtual={false}
            mode="multiple"
            placeholder="Reentry"
            filterOption={false}
            style={{ minWidth: 70 }}
            options={reentryList.map((label) => ({ value: label, label }))}
          />
        </Form.Item>
        <Form.Item name="startBBFrom">
          <InputNumber placeholder="Min Start BB" />
        </Form.Item>
        <Form.Item name="startBBTo">
          <InputNumber placeholder="Max Start BB" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Search
          </Button>
        </Form.Item>
      </Space>
    </Form>
  );
};
