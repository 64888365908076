import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Divider, Space, Spin, Table } from 'antd';
import { ACCOUNT_REGIONS } from 'config/accounts';
import { getTransferConfig } from 'store/transfer-config';
import useLoading from 'hooks/useLoading';
import CountrySelect from 'components/CountrySelect';
import AccountSelect from '../components/AccountSelect';
import StatusCheckbox from '../components/StatusCheckbox';

const Grid = () => {
  const [region, setRegion] = useState();
  const [account, setAccount] = useState();
  const dispatch = useDispatch();
  const { data, loading, execute } = useLoading({ docs: [] });

  useEffect(() => {
    if (region && account) {
      execute(() => dispatch(getTransferConfig({ type: account, countryFrom: region }, undefined)));
    }
  }, [region, account]);

  const columns = useMemo(() => {
    const cols = [
      {
        key: 'countryTo',
        dataIndex: 'countryTo',
        title: 'Country To',
      },
    ];

    if (ACCOUNT_REGIONS[account]) {
      ACCOUNT_REGIONS[account].statuses.forEach((status) => {
        cols.push({
          key: status,
          title: status,
          align: 'center',
          render: (_, config) => <StatusCheckbox config={config} value={status} />,
        });
      });
    }

    return cols;
  }, [account]);

  return (
    <>
      <Space>
        <AccountSelect placeholder="Select account" value={account} onChange={setAccount} style={{ width: 200 }} />
        <CountrySelect placeholder="Select country" value={region} onChange={setRegion} style={{ width: 300 }} />
      </Space>
      <Divider />
      {loading ? (
        <div style={{ textAlign: 'center', padding: 24 }}>
          <Spin />
        </div>
      ) : (
        <Table dataSource={data.docs} rowKey="_id" pagination={false} columns={columns} />
      )}
    </>
  );
};

export default Grid;
