import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form, Modal, notification, Space } from 'antd';
import { useToggle } from 'hooks/useToggle';
import { downloadArchivedRequests } from 'store/playing-myself';
import DatePicker from 'components/Form/DatePicker';

const DownloadTransactionsModal = () => {
  const dispatch = useDispatch();
  const { active, activate, deactivate } = useToggle();
  const [form] = Form.useForm();

  const downloadTransactions = useCallback(
    async ({ startDate, endDate, ...rest }) => {
      const filter = {
        ...rest,
        startDate: startDate ? startDate.startOf('day').unix() : undefined,
        endDate: endDate ? endDate.endOf('day').unix() : undefined,
      };

      try {
        const data = await dispatch(downloadArchivedRequests(filter));
        if (data.fileURL) {
          window.open(data.fileURL, '_blank');
          deactivate();
          notification.success({
            message: 'File was successfully downloaded',
          });
        } else {
          notification.error({
            message: 'Error while downloading file',
          });
        }
      } catch (e) {
        notification.error({
          message: 'Error while downloading file',
        });
      }
    },
    [dispatch, deactivate],
  );

  return (
    <>
      <Button type="primary" onClick={activate}>
        Download Archive
      </Button>
      <Modal
        title="Download Archive CSV"
        forceRender
        open={active}
        okText="Download"
        onOk={form.submit}
        onCancel={deactivate}
      >
        <Form form={form} onFinish={downloadTransactions}>
          <Space style={{ width: '100%', justifyContent: 'space-between' }}>
            <Form.Item name="startDate" label="Start date" style={{ marginBottom: 0 }}>
              <DatePicker />
            </Form.Item>
            <Form.Item
              name="endDate"
              label="End date"
              style={{ marginBottom: 0 }}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const startDate = getFieldValue('startDate');
                    if (value && startDate && value.diff(startDate) < 0) {
                      return Promise.reject(new Error('End date cannot be before start date'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <DatePicker />
            </Form.Item>
          </Space>
        </Form>
      </Modal>
    </>
  );
};

export default DownloadTransactionsModal;
