import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'notifications',
  initialState: {
    list: {
      page: 1,
      hasNextPage: true,
      docs: [],
    },
    active: [],
  },
  reducers: {
    notificationsRequestStarted: (notifications) => {
      notifications.loading = true;
    },
    notificationsRequestEnded: (notifications) => {
      notifications.loading = false;
    },
    notificationsReceived: (notifications, action) => {
      notifications.active = action.payload.data;
    },
    notificationsListReceived: (notifications, action) => {
      notifications.list = action.payload.data;
    },
    notificationsListLoaded: (notifications, action) => {
      notifications.list = {
        ...action.payload.data,
        docs: [...notifications.list.docs, ...action.payload.data.docs],
      };
    },
  },
});

export const {
  notificationsRequestStarted,
  notificationsRequestEnded,
  notificationsReceived,
  notificationsListReceived,
  notificationsListLoaded,
} = slice.actions;

export default slice.reducer;

//api calls
export const getAll = (params = {}) =>
  apiCallBegan({
    url: '/notifications',
    params,
    onStart: notificationsRequestStarted,
    onSuccess: params.pagination?.page > 1 ? notificationsListLoaded : notificationsListReceived,
    onEnd: notificationsRequestEnded,
  });

export const getReviews = (notificationId, params = {}) =>
  apiCallBegan({
    url: `/notifications/${notificationId}/reviews`,
    params,
  });

export const getReviewsByUserId = (userId, params = {}) =>
  apiCallBegan({
    url: `/notifications/users/${userId}/reviews`,
    params,
  });

export const getMy = (params = {}) =>
  apiCallBegan({
    url: '/notifications/my',
    params,
    onStart: notificationsRequestStarted,
    onSuccess: params.pagination?.page > 1 ? notificationsListLoaded : notificationsListReceived,
    onEnd: notificationsRequestEnded,
  });

export const getMyActive = () =>
  apiCallBegan({
    url: '/notifications/my/active',
    onSuccess: notificationsReceived,
  });

export const review = (notificationId) =>
  apiCallBegan({
    url: `/notifications/${notificationId}`,
    method: 'PUT',
  });

export const create = (data) =>
  apiCallBegan({
    url: '/notifications',
    method: 'POST',
    data,
  });

export const duplicate = (notificationId) =>
  apiCallBegan({
    url: `/notifications/${notificationId}/duplicate`,
    method: 'POST',
  });

export const update = (notificationId, data) =>
  apiCallBegan({
    url: `/notifications/${notificationId}`,
    method: 'PATCH',
    data,
  });

export const activate = (notificationId) =>
  apiCallBegan({
    url: `/notifications/${notificationId}/activate`,
    method: 'PATCH',
  });

export const remove = (notificationId) =>
  apiCallBegan({
    url: `/notifications/${notificationId}`,
    method: 'DELETE',
  });

// selectors
export const selectNotifications = createSelector(
  (state) => state.notifications,
  (notifications) => notifications.list,
);
