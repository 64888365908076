import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Form, Input, InputNumber, Row, Select } from 'antd';
import { CountryRegionData } from 'react-country-region-selector';
import debounce from 'lodash.debounce';
import { generatePassword, getMaxPercent } from 'utils/users';
import { toBuyInPercent, toUpperCase } from 'utils/format';
import { rangeWithStep } from 'utils/generators';
import { validateUnique } from 'store/users';
import { useDegree } from '../../hooks';

const PersonalInfo = ({ form, onChange }) => {
  const dispatch = useDispatch();
  const [maxBuyInPercent, setMaxBuyInPercent] = useState(0);

  const onDegreeChange = useCallback(
    (valid, value) => {
      const resetFields = ['buyInPercent'];
      if (!valid) {
        resetFields.push('course');
      }
      setMaxBuyInPercent(getMaxPercent(value));
      form.resetFields(resetFields);
    },
    [form],
  );
  const { degree, onCourseChange } = useDegree(onDegreeChange);

  const checkUniqueness = useCallback(
    async ({ field }, value) => {
      try {
        await dispatch(validateUnique({ [field]: value }));
      } catch (e) {
        const code = e.response?.status;
        if (code === 409) {
          throw new Error('Already exists');
        }

        throw new Error('Something went wrong');
      }
    },
    [dispatch],
  );

  const debouncedCheckUniqueness = debounce(checkUniqueness, 500);

  const validateUniqueness = useCallback(
    () => ({
      validator: debouncedCheckUniqueness,
    }),
    [debouncedCheckUniqueness],
  );

  const buyInPercents = useMemo(
    () => rangeWithStep(0, maxBuyInPercent, 5).map((p) => ({ v: p, key: toBuyInPercent(p) })),
    [maxBuyInPercent],
  );

  const handleFieldChange = useCallback(
    (_, fields) => onChange(!fields.some((field) => !!field.errors.length)),
    [onChange],
  );

  return (
    <Form
      name="create-user"
      form={form}
      layout="vertical"
      initialValues={{ ABI: 10, password: generatePassword(), buyInPercent: null }}
      onFieldsChange={handleFieldChange}
    >
      <Row gutter={10}>
        <Col span={12}>
          <Form.Item
            label="Login"
            name="username"
            rules={[{ required: true, message: 'Required' }, validateUniqueness]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="E-mail"
            name="email"
            rules={[{ required: true, type: 'email', message: 'Please, use the valid email' }, validateUniqueness]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col span={12}>
          <Form.Item
            label="Full Name (Latin)"
            name="name"
            rules={[
              { required: true, message: 'Required' },
              { pattern: new RegExp('^[a-zA-Z\\s\\-]*$'), message: 'Use latin letters only' },
            ]}
            normalize={toUpperCase}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Full Name (Cyrillic)"
            name="nameRus"
            rules={[
              {
                pattern: new RegExp('^[А-яЁёўЎЇїІіЄєҐґ\\s\\-]*$'),
                message: 'Use cyrillic letters only',
                required: false,
              },
            ]}
            normalize={toUpperCase}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={10} style={{ marginBottom: -24 }}>
        <Col span={12}>
          <Form.Item label="Country" name="country" rules={[{ required: true, message: 'Required' }]}>
            <Select name="country" showSearch>
              {CountryRegionData.map((country) => (
                <Select.Option key={country[1]} value={country[0]}>
                  {country[0]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Course" name="course" rules={[{ required: true, message: 'Required' }]}>
            <InputNumber precision={0} onChange={onCourseChange} addonAfter={degree} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="Conditions" name="buyInPercent" rules={[{ required: true, message: 'Required' }]}>
            <Select name="buyInPercent" style={{ width: '100%' }} virtual={false}>
              {buyInPercents.map(({ key, v }) => (
                <Select.Option value={v} key={key}>
                  {key}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item hidden name="password" rules={[{ required: true }]} />
    </Form>
  );
};

export default PersonalInfo;
