import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useToggle } from 'hooks/useToggle';
import { useTableParams } from 'hooks/useTableParams';
import { toDate } from 'utils/format';
import { getReviewsByUserId } from 'store/notifications';
import { UserSelect } from 'components/Form/UserSelect';

const columns = [
  { key: 'title', dataIndex: 'notification', width: 600, ellipsis: true, title: 'Title', render: (notification) => notification.title },
  {
    key: 'notificationUpdatedAt',
    dataIndex: 'notification',
    title: 'Sent At',
    render: (notification) => toDate(notification.updatedAt),
  },
  { key: 'createdAt', dataIndex: 'createdAt', title: 'Read At', render: (createdAt) => toDate(createdAt) },
];

const UserReviewsModal = () => {
  const dispatch = useDispatch();
  const { active, activate, deactivate } = useToggle();
  const [reviews, setReviews] = useState([]);
  const { params, handleTableChange } = useTableParams();
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    if (userId) {
      // noinspection JSUnresolvedReference
      dispatch(getReviewsByUserId(userId, params)).then(({ data }) => setReviews(data));
    }
  }, [dispatch, userId, params]);

  const close = useCallback(() => {
    setReviews([]);
    deactivate();
    setUserId(null);
  }, [deactivate]);

  return (
    <>
      <Button title="Reviews" type="primary" icon={<SearchOutlined />} onClick={activate}>
        Reviews by user
      </Button>
      <Modal
        title={
          <>
            Get reviews of <UserSelect style={{ paddingLeft: 5 }} value={userId} onChange={setUserId} />
          </>
        }
        open={active}
        onCancel={close}
        footer={null}
        width={1000}
      >
        <Table
          columns={columns}
          rowKey="_id"
          dataSource={reviews.docs}
          onChange={handleTableChange}
          style={{ marginBottom: -24 }}
        />
      </Modal>
    </>
  );
};

export default UserReviewsModal;
