import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Select } from 'antd';
import { CountryRegionData } from 'react-country-region-selector';
import debounce from 'lodash.debounce';
import { generatePassword } from 'utils/users';
import { toUpperCase } from 'utils/format';
import { validateUnique } from 'store/users';

const PersonalInfo = ({ form, onChange }) => {
  const dispatch = useDispatch();

  const checkUniqueness = useCallback(
    async ({ field }, value) => {
      try {
        await dispatch(validateUnique({ [field]: value }));
      } catch (e) {
        const code = e.response?.status;
        if (code === 409) {
          throw new Error('Already exists');
        }

        throw new Error('Something went wrong');
      }
    },
    [dispatch],
  );

  const debouncedCheckUniqueness = debounce(checkUniqueness, 500);

  const validateUniqueness = useCallback(
    () => ({
      validator: debouncedCheckUniqueness,
    }),
    [debouncedCheckUniqueness],
  );

  const handleFieldChange = useCallback(
    (_, fields) => onChange(!fields.some((field) => !!field.errors.length)),
    [onChange],
  );

  return (
    <Form
      name="create-user"
      form={form}
      layout="vertical"
      initialValues={{ password: generatePassword(), role: 'admin', permissions: ['all'] }}
      onFieldsChange={handleFieldChange}
    >
      <Form.Item label="Login" name="username" rules={[{ required: true, message: 'Required' }, validateUniqueness]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="E-mail"
        name="email"
        rules={[{ required: true, type: 'email', message: 'Please, use the valid email' }, validateUniqueness]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Full Name (Latin)"
        name="name"
        rules={[
          { required: true, message: 'Required' },
          { pattern: new RegExp('^[a-zA-Z\\s\\-]*$'), message: 'Use latin letters only' },
        ]}
        normalize={toUpperCase}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Full Name (Cyrillic)"
        name="nameRus"
        rules={[
          {
            pattern: new RegExp('^[А-яЁёўЎЇїІіЄєҐґ\\s\\-]*$'),
            message: 'Use cyrillic letters only',
            required: false,
          },
        ]}
        normalize={toUpperCase}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Country"
        name="country"
        rules={[{ required: true, message: 'Required' }]}
        style={{ marginBottom: 0 }}
      >
        <Select name="country" showSearch>
          {CountryRegionData.map((country) => (
            <Select.Option key={country[1]} value={country[0]}>
              {country[0]}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item hidden name="password" rules={[{ required: true }]} />
    </Form>
  );
};

export default PersonalInfo;
