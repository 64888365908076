import { Col, Row, Statistic } from 'antd';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';

const integerFormatter = (value) => <CountUp duration={1} end={value} />;
const decimalFormatter = (value) => <CountUp duration={1} decimals={2} end={value} />;

const CraftSummary = ({ summary }) => {
  const { t } = useTranslation();

  const fields = [
    {
      title: t('craft.summary.total_count'),
      value: summary.totalCount,
      precision: 0,
      formatter: integerFormatter,
    },
    {
      title: t('craft.summary.total_entries'),
      value: summary.totalEntries,
      precision: 0,
      formatter: integerFormatter,
    },
    {
      title: t('craft.summary.profit'),
      value: summary.profit,
      precision: 2,
      suffix: '$',
      formatter: decimalFormatter,
    },
    {
      title: t('craft.summary.abi'),
      value: summary.avgBuyIn,
      precision: 2,
      suffix: '$',
      formatter: decimalFormatter,
    },
    {
      title: t('craft.summary.avg_profit'),
      value: summary.avgProfit,
      precision: 2,
      suffix: '$',
      formatter: decimalFormatter,
    },
    {
      title: t('craft.summary.roi'),
      value: summary.roi,
      precision: 2,
      suffix: '%',
      formatter: decimalFormatter,
    },
    {
      title: t('craft.summary.staked'),
      value: summary.staked,
      precision: 2,
      suffix: '$',
      formatter: decimalFormatter,
    },
    {
      title: t('craft.summary.reentries'),
      value: summary.totalReEntries,
      precision: 0,
      formatter: integerFormatter,
    },
    {
      title: t('craft.summary.reentries') + ' %',
      value: summary.reEntryPercentage,
      precision: 2,
      suffix: '%',
      formatter: decimalFormatter,
    },
  ];

  return (
    <Row gutter={32}>
      {fields.map((field, index) => (
        <Col span={index % 2 === 0 ? 3 : 2} key={index}>
          <Statistic
            title={field.title}
            value={field.value}
            precision={field.precision}
            suffix={field.suffix}
            formatter={field.formatter}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
        </Col>
      ))}
    </Row>
  );
};

export default CraftSummary;
