import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Popconfirm, Table, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { toAmount } from 'utils/format';
import { useTableParams } from 'hooks/useTableParams';
import { deleteOne } from 'store/transfers';

const getHoursBetweenDates = (date1, date2) => {
  const diff = Math.abs(date1 - date2);
  return Math.ceil(diff / (1000 * 60 * 60));
};

const TransfersTable = ({ list, loading, onChange }) => {
  const dispatch = useDispatch();
  const { params, handleTableChange } = useTableParams({
    pagination: { page: 1, pageSize: 10 },
    sort: { createdAt: 1 },
  });

  useEffect(() => {
    onChange(params);
  }, [onChange, params]);

  const deleteRequest = useCallback(
    async (id) => {
      await dispatch(deleteOne(id));
      onChange(params);
    },
    [dispatch, onChange, params],
  );

  const columns = useMemo(
    () => [
      {
        title: 'User',
        dataIndex: 'user',
        key: 'user',
        render: (user) => user.username,
      },
      {
        title: 'Account',
        dataIndex: 'typeCurrency',
        key: 'account',
      },
      {
        title: 'Amount',
        dataIndex: 'currentAmount',
        key: 'currentAmount',
        render: (currentAmount, transfer) =>
          toAmount(transfer.requestedAmount - currentAmount, transfer.account.currency),
      },
      {
        title: 'Date',
        dataIndex: 'createdAt',
        key: 'date',
        render: (createdAt) => new Date(createdAt).toLocaleString(),
      },
      {
        width: 200,
        title: 'Comment',
        dataIndex: 'comment',
        key: 'comment',
        ellipsis: true,
        className: 'text-cell-ellipsis',
        render: (comment) => (comment ? <Tooltip title={comment}>{comment}</Tooltip> : '-'),
      },
      {
        title: 'Actions',
        dataIndex: '_id',
        key: 'actions',
        align: 'center',
        render: (id) => (
          <Popconfirm title="Are you sure?" onConfirm={() => deleteRequest(id)}>
            <Button type="primary" danger icon={<DeleteOutlined />} />
          </Popconfirm>
        ),
      },
    ],
    [deleteRequest],
  );

  return (
    <Table
      rowClassName={(record) =>
        getHoursBetweenDates(new Date(), new Date(record.createdAt)) > 24 ? 'transfer-expired' : ''
      }
      loading={loading}
      rowKey="_id"
      pagination={{ total: list.totalDocs, pageSize: list.limit, current: list.page, style: { marginBottom: 0 } }}
      dataSource={list.docs}
      columns={columns}
      onChange={handleTableChange}
    />
  );
};

export default TransfersTable;
