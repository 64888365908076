import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row } from 'antd';
import { getMyAccounts } from 'store/accounts';
import { getPreferences } from 'store/preferences';
import MyRooms from './fragments/MyRooms';
import AccountRegions from './fragments/AccountRegions';

const Settings = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMyAccounts());
    dispatch(getPreferences());
  }, [dispatch]);

  return (
    <Row gutter={10}>
      <MyRooms />
      <AccountRegions />
    </Row>
  );
};

export default Settings;
