import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Col, Form, Modal, Row } from 'antd';
import { ACCOUNT_REGIONS } from 'config/accounts';
import { selectIsLoading } from 'store/transfer-config';
import CountrySelect from 'components/CountrySelect';
import AccountSelect from './AccountSelect';

const ConfigModal = ({ config, title, active, onSubmit, onClose }) => {
  const [form] = Form.useForm();
  const [currentStatuses, setCurrentStatuses] = useState([]);
  const isLoading = useSelector(selectIsLoading);

  const handleAccountChange = useCallback((account) => {
    setCurrentStatuses(ACCOUNT_REGIONS[account].statuses);
  }, []);

  useEffect(() => {
    if (config) {
      form.setFieldsValue({
        type: config.type,
        countryFrom: config.countryFrom,
        statusesFrom: config.statusesFrom,
        countryTo: config.countryTo,
        statusesTo: config.statusesTo,
      });

      handleAccountChange(config.type);
    }
  }, [config, form.setFieldsValue, handleAccountChange]);

  const close = useCallback(() => {
    form.resetFields();
    onClose?.();
  }, [form, onClose]);

  const handleSubmit = useCallback(
    async (values) => {
      try {
        await onSubmit(values);
        close();
      } catch (e) {
        console.error(e);
      }
    },
    [close],
  );

  const hasCurrentStatuses = currentStatuses.length > 0;

  return (
    <Modal
      width={500}
      title={title}
      open={active}
      onOk={form.submit}
      confirmLoading={isLoading}
      okText="Save"
      onCancel={close}
    >
      <Form layout="vertical" form={form} onFinish={handleSubmit}>
        <Form.Item label="Account" name="type" rules={[{ required: true, message: 'Required' }]}>
          <AccountSelect onChange={handleAccountChange} />
        </Form.Item>
        <Row gutter={10} style={{ marginBottom: -24 }}>
          <Col span={12}>
            <Form.Item label="Country from" name="countryFrom" rules={[{ required: true, message: 'Required' }]}>
              <CountrySelect />
            </Form.Item>
            {hasCurrentStatuses && (
              <Form.Item label="Statuses from" name="statusesFrom" rules={[{ required: true, message: 'Required' }]}>
                <Checkbox.Group>
                  {currentStatuses.map((status) => (
                    <Checkbox key={status} value={status} style={{ width: '100%', marginLeft: 0 }}>
                      {status}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            )}
          </Col>
          <Col span={12}>
            <Form.Item label="Country to" name="countryTo" rules={[{ required: true, message: 'Required' }]}>
              <CountrySelect />
            </Form.Item>
            {hasCurrentStatuses && (
              <Form.Item label="Statuses to" name="statusesTo" rules={[{ required: true, message: 'Required' }]}>
                <Checkbox.Group>
                  {currentStatuses.map((status) => (
                    <Checkbox key={status} value={status} style={{ width: '100%', marginLeft: 0 }}>
                      {status}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            )}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ConfigModal;
