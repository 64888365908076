import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, notification, Popconfirm } from 'antd';
import { toLocalTime } from 'utils/format';
import { calculate, getLatest } from 'store/user-stats';

export const TriggerCalculation = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState('');

  useEffect(() => {
    dispatch(getLatest()).then((res) => {
      setLastUpdated(toLocalTime(res.data?.updatedAt));
    });
  }, [dispatch]);

  const triggerCalculation = useCallback(async () => {
    await dispatch(calculate());
    notification.success({
      message: 'Users statistics calculation has been triggered. This may take up to several minutes',
    });

    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      window.location.reload();
    }, 10000);
  }, [dispatch]);

  return (
    <Popconfirm title="Users statistics recalculation will be triggered, continue?" onConfirm={triggerCalculation}>
      <Button type="primary" loading={loading} title={lastUpdated}>
        Trigger calculation
      </Button>
    </Popconfirm>
  );
};
