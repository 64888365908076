import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'antd';
import { useTableParams } from 'hooks/useTableParams';
import { findAllMy, getMySummary, selectCraft } from 'store/craft';
import CraftSummary from 'components/Craft/CraftSummary';
import CraftSearch from 'components/Craft/CraftSearch';
import CraftTable from 'components/Craft/CraftTable';
import UploadArchiveModal from './fragments/UploadArchiveModal';

export const Craft = () => {
  const dispatch = useDispatch();
  const { list, summary, loading } = useSelector(selectCraft);
  const { params, handleTableChange, addFilter } = useTableParams();

  useEffect(() => {
    dispatch(findAllMy(params));
    dispatch(getMySummary(params));
  }, [dispatch, params]);

  const isSummaryEmpty = useMemo(() => Object.keys(summary).length === 0 && summary.constructor === Object, [summary]);

  return (
    <>
      <UploadArchiveModal />
      <Divider />
      <CraftSearch onChange={addFilter} />
      <Divider />
      {!isSummaryEmpty && (
        <>
          <CraftSummary summary={summary} />
          <Divider />
        </>
      )}
      <CraftTable list={list} loading={loading} onChange={handleTableChange} />
    </>
  );
};
