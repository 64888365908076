import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { withFormErrors } from 'utils/common';
import { useToggle } from 'hooks/useToggle';
import { ROOMS } from 'config/tournaments';
import { selectSerialTournamentsLoading, updateSerialTournament } from 'store/serial-tournaments';
import InputNumber from 'components/Form/InputNumber';
import DatePicker from 'components/Form/DatePicker';

const roomOptions = ROOMS.map((value) => ({ value, label: value }));

export const UpdateSerialModal = ({ tournament }) => {
  const dispatch = useDispatch();
  const { active, activate, deactivate } = useToggle();
  const [form] = Form.useForm();
  const loading = useSelector(selectSerialTournamentsLoading);

  useEffect(() => {
    form.setFieldsValue({
      name: tournament.name,
      startDate: moment(tournament.startDate),
      room: tournament.room,
      buyIn: tournament.buyIn,
      course: tournament.course,
      quality: tournament.quality,
      type: tournament.type,
    });
  }, [form, tournament]);

  const onSubmit = useCallback(
    async (values) =>
      withFormErrors(form, async () => {
        await dispatch(updateSerialTournament(tournament._id, values));
        deactivate();
      }),
    [form, tournament, deactivate, dispatch],
  );

  return (
    <>
      <Button type="primary" onClick={activate} icon={<EditOutlined />} />
      <Modal
        confirmLoading={loading}
        open={active}
        title="Update serial tournament"
        onOk={form.submit}
        onCancel={deactivate}
      >
        <Form name="update-tournament" form={form} layout="vertical" onFinish={onSubmit}>
          <Row gutter={10}>
            <Col span={18}>
              <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Row gutter={10}>
                <Col span={16}>
                  <Form.Item label="Start Date" name="startDate" rules={[{ required: true }]}>
                    <DatePicker showTime style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Room" name="room" rules={[{ required: true }]}>
                    <Select virtual={false} style={{ width: '100%' }} options={roomOptions} />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Passphrase" name="passphrase" rules={[{ required: true }]}>
                <Input.Password />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Buy-in" name="buyIn" rules={[{ required: true }]} style={{ alignItems: 'end' }}>
                <InputNumber min={0} />
              </Form.Item>
              <Form.Item label="Course" name="course" rules={[{ required: true }]} style={{ alignItems: 'end' }}>
                <InputNumber min={0} />
              </Form.Item>
              <Form.Item label="Quality" name="quality" rules={[{ required: true }]} style={{ alignItems: 'end' }}>
                <InputNumber min={0} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
