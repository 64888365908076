export const permissions = {
  //users
  CanReadUsers: 'CanReadUsers',
  CanManageUsers: 'CanManageUsers',
  //admins
  CanManageAdmins: 'CanManageAdmins',
  CanManagePackages: 'CanManagePackages',
  //tournaments
  CanReadTournaments: 'CanReadTournaments',
  CanUploadTournaments: 'CanUploadTournaments',
  CanEditSerialTournaments: 'CanEditSerialTournaments',
  CanDeleteSerialTournaments: 'CanDeleteSerialTournaments',
  //user-stats
  CanReadUserStats: 'CanReadUserStats',
  //account-stats
  CanReadAccountStats: 'CanReadAccountStats',
  //account-logs
  CanReadAccountLogs: 'CanReadAccountLogs',
  //personal-transactions
  CanReadPersonalTransactions: 'CanReadPersonalTransactions',
  //internal-transactions
  CanReadInternalTransactions: 'CanReadInternalTransactions',
  //external-transactions
  CanReadExternalTransactions: 'CanReadExternalTransactions',
  //split
  CanReadSplit: 'CanReadSplit',
  //freeze
  CanReadFreeze: 'CanReadFreeze',
  CanFreeze: 'CanFreeze',
  //playing-myself
  CanReadPlayingMyself: 'CanReadPlayingMyself',
  //serial-evaluation
  CanReadSerialEvaluation: 'CanReadSerialEvaluation',
  //totals
  CanReadTotals: 'CanReadTotals',
  //analytics
  CanReadAnalytics: 'CanReadAnalytics',
  //craft
  CanReadCraft: 'CanReadCraft',
};
