import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import { useToggle } from 'hooks/useToggle';
import { createTransferConfig } from 'store/transfer-config';
import ConfigModal from '../components/ConfigModal';

const CreateConfigModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const { active, activate, deactivate } = useToggle();

  const handleSubmit = useCallback(
    async (values) => {
      try {
        await dispatch(createTransferConfig(values));
        deactivate();
        onClose?.();
      } catch (e) {
        console.error(e);
      }
    },
    [dispatch, deactivate, onClose],
  );

  return (
    <>
      <Button type="primary" onClick={activate}>
        Create
      </Button>
      <ConfigModal active={active} title="Create setting" onSubmit={handleSubmit} onClose={deactivate} />
    </>
  );
};

export default CreateConfigModal;
