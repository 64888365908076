import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal, notification, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useFileUpload } from 'hooks/useFileUpload';
import { useToggle } from 'hooks/useToggle';
import { importArchive, selectCraft } from 'store/craft';

const UploadArchiveModal = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector(selectCraft);
  const { active, activate, deactivate } = useToggle();
  const { fileList, handleChange, setStatus } = useFileUpload();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const onClose = useCallback(() => {
    deactivate();
    form.resetFields();
  }, [deactivate, form]);

  const onFinish = useCallback(
    async (values) => {
      const formData = new FormData();
      formData.append('file', values.file.file, values.file.file.name);

      try {
        await dispatch(importArchive(formData));
        notification.success({ message: t('craft.upload.modal.success') });
        onClose();
        setStatus('success');
      } catch (e) {
        notification.error({ message: t('craft.upload.modal.fail') });
      }
    },
    [dispatch, onClose, setStatus],
  );

  return (
    <>
      <Button type="primary" onClick={activate}>
        <UploadOutlined /> {t('craft.upload.button')}
      </Button>
      <Modal
        title={t('craft.upload.modal.title')}
        forceRender
        open={active}
        onOk={form.submit}
        okText={t('craft.upload.modal.ok')}
        cancelText={t('craft.upload.modal.cancel')}
        onCancel={onClose}
        confirmLoading={loading}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item
            label={t('craft.upload.modal.label.file')}
            name="file"
            rules={[{ required: true }]}
            style={{ marginBottom: 0 }}
          >
            <Upload
              accept=".zip"
              maxCount={1}
              onChange={handleChange}
              fileList={fileList}
              beforeUpload={() => false}
              listType="picture"
            >
              <Button icon={<UploadOutlined />}>{t('selectFile')}</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UploadArchiveModal;
