import { memo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox } from 'antd';
import { updateTransferConfig } from 'store/transfer-config';

const StatusCheckbox = ({ value, config }) => {
  const dispatch = useDispatch();

  const handleChange = useCallback(
    (e) => {
      dispatch(
        updateTransferConfig(config._id, {
          ...config,
          statusesFrom: e.target.checked
            ? [...config.statusesFrom, value]
            : config.statusesFrom.filter((status) => status !== value),
        }),
      );
    },
    [dispatch, config],
  );

  return (
    <Checkbox onChange={handleChange} key={value} value={value} defaultChecked={config.statusesFrom.includes(value)} />
  );
};

export default memo(StatusCheckbox);
