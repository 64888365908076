import { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Select } from 'antd';
import { getRoles } from 'store/rbac';

export const RolesSelect = ({ onLoad, ...props }) => {
  const dispatch = useDispatch();
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const fetchRoles = async () => {
      const data = await dispatch(getRoles());
      setRoles(data);
      if (onLoad) {
        onLoad(data);
      }
    };

    fetchRoles();
  }, [dispatch, onLoad]);

  return (
    <Select {...props}>
      {roles.map((role) => (
        <Select.Option value={role._id} key={role.name}>
          {role.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export const RolesContext = createContext();

export const RolesProvider = ({ onLoad, children }) => {
  const dispatch = useDispatch();
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const fetchRoles = async () => {
      const data = await dispatch(getRoles());
      setRoles(data.map((item) => ({ value: item._id, label: item.name })));
      if (onLoad) {
        onLoad(data);
      }
    };

    fetchRoles();
  }, [dispatch, onLoad]);

  return <RolesContext.Provider value={roles}>{children}</RolesContext.Provider>;
};
