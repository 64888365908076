import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useToggle } from 'hooks/useToggle';
import { updateTransferConfig } from 'store/transfer-config';
import ConfigModal from '../components/ConfigModal';

const UpdateConfigModal = ({ config, onClose }) => {
  const dispatch = useDispatch();
  const { active, activate, deactivate } = useToggle();

  const handleSubmit = useCallback(
    async (values) => {
      try {
        await dispatch(updateTransferConfig(config._id, values));
        deactivate();
        onClose?.();
      } catch (e) {
        console.error(e);
      }
    },
    [dispatch, deactivate, onClose, config],
  );

  return (
    <>
      <Button type="primary" icon={<EditOutlined />} onClick={activate} />
      <ConfigModal
        active={active}
        title="Update setting"
        config={config}
        onSubmit={handleSubmit}
        onClose={deactivate}
      />
    </>
  );
};

export default UpdateConfigModal;
