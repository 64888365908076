import { Button, Modal } from 'antd';
import { useToggle } from 'hooks/useToggle';
import { EyeOutlined } from '@ant-design/icons';

const ViewNotificationModal = ({ notification }) => {
  const { active, activate, deactivate } = useToggle();

  return (
    <>
      <Button icon={<EyeOutlined />} type="primary" onClick={activate} />
      <Modal open={active} onCancel={deactivate} title={notification.title} width={1000} footer={null}>
        <div style={{ overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: notification.description }} />
      </Modal>
    </>
  );
};

export default ViewNotificationModal;
