import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Avatar, Button, Layout, Menu, Result } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { selectUser, logout, can } from 'store/auth';
import { syncTimezone } from 'store/users';
import { RateModal } from 'components/Rates/RateModal';
import { TriggerCalculation } from 'components/TriggerCalculation';
import { ROUTES } from './config';
import { getSideMenu } from '../utils';

const { Header, Content, Sider } = Layout;

const Admin = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(localStorage.getItem('menuCollapsed') === 'true');
  const user = useSelector(selectUser);
  const hasPermission = useSelector(can);

  const routes = useMemo(() => ROUTES.filter((route) => hasPermission(route.permission)), [hasPermission]);

  const sideMenu = useMemo(() => getSideMenu({ routes }), [routes]);

  useEffect(() => {
    dispatch(syncTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone));
  }, [dispatch]);

  const chunks = location.pathname.split('/');

  const signOut = useCallback(() => dispatch(logout()), [dispatch]);

  const setSideMenuCollapsed = useCallback((value) => {
    setCollapsed(value);
    localStorage.setItem('menuCollapsed', value);
  }, []);

  const topMenu = useMemo(
    () => [
      {
        key: 'trigger-calculation',
        label: <TriggerCalculation />,
      },
      {
        key: 'rates',
        label: <RateModal />,
      },
      {
        key: 'user-actions',
        label: (
          <>
            <span>Hi, {user?.username}</span>
            <Avatar style={{ marginLeft: 8 }} src={user?.avatar} />
          </>
        ),
        type: 'subMenu',
        children: [
          {
            key: 'logout',
            icon: <LogoutOutlined />,
            label: 'Log out',
            onClick: signOut,
          },
        ],
      },
    ],
    [user, signOut],
  );

  return (
    <Layout>
      <Sider collapsible collapsed={collapsed} onCollapse={setSideMenuCollapsed}>
        <div style={{ marginLeft: 18, marginTop: 9, marginBottom: 7, marginRight: 18 }}>
          <div style={{ height: 44 }} />
        </div>
        <Menu theme="dark" defaultSelectedKeys={[`/${chunks[1]}`]} mode="inline" items={Object.values(sideMenu)} />
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-header" style={{ padding: 0 }}>
          <Menu
            mode="horizontal"
            theme="dark"
            style={{ justifyContent: 'end' }}
            selectedKeys={['rates']}
            items={topMenu}
          />
        </Header>
        <Content style={{ margin: '0 16px' }}>
          <div className="site-layout-content">
            <Switch>
              <Route exact path="/">
                {!!routes[0] ? (
                  <Redirect to={routes[0].path} />
                ) : (
                  <Result
                    status="403"
                    title="403"
                    subTitle="You don't have permission to get this resource"
                    extra={
                      <Button type="primary" onClick={signOut}>
                        Logout
                      </Button>
                    }
                  />
                )}
              </Route>
              {routes.map(({ path, Component }) => (
                <Route key={path} exact path={path}>
                  <Component />
                </Route>
              ))}
              <Route>
                <Result
                  status="404"
                  title="404"
                  subTitle="Sorry, the page you visited does not exist."
                  extra={
                    <Link to="/">
                      <Button type="primary">Back Home</Button>
                    </Link>
                  }
                />
              </Route>
            </Switch>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Admin;
