import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { getUserTotals } from 'store/statistics';

export const FreezeInfo = ({ frozenPackage }) => {
  const dispatch = useDispatch();
  const [afterSplit, setAfterSplit] = useState(null);
  const [visible, setVisible] = useState(false);

  const loadContent = useCallback(
    async (open) => {
      if (open && !afterSplit) {
        const data = await dispatch(getUserTotals(frozenPackage.user));
        if (data.packageProfit < 0) {
          setAfterSplit(frozenPackage.onFreeze);
        } else {
          const toUnfreeze = data.packageProfit / 2;
          setAfterSplit(toUnfreeze > frozenPackage.onFreeze ? 0 : frozenPackage.onFreeze - toUnfreeze);
        }
      }
      setVisible(open);
    },
    [frozenPackage, afterSplit],
  );

  return (
    <Tooltip trigger="click" title={`After split: ${afterSplit} $`} open={visible} onOpenChange={loadContent}>
      <InfoCircleOutlined />
    </Tooltip>
  );
};
