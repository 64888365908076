export const types = [
  {
    value: 1,
    label: 'pko regspeed',
  },
  {
    value: 2,
    label: '8-9 max non-pko regspeed',
  },
  {
    value: 3,
    label: '5-7 max non-pko regspeed',
  },
  {
    value: 4,
    label: '5-7 max pko turbo',
  },
  {
    value: 5,
    label: '5-7 max pko hyper',
  },
  {
    value: 6,
    label: '8-9 max pko turbo',
  },
  {
    value: 7,
    label: '5-7 max non-pko turbo/hyper',
  },
  {
    value: 8,
    label: '8-9 max non-pko turbo/hyper',
  },
];

export const ROOMS = ['PS', 'PSes', '888', 'WMX', 'WPN', 'GG', '4Poker', 'Coin', 'WPT', 'IP', 'Chico'];

// noinspection JSNonASCIINames
export const ROOMS_MAP = {
  Redstar: 'IP',
  Titan: 'IP',
  Betsafe: 'IP',
  BetFair: 'IP',
  ChampionPoker: 'IP',
  CoinPoker: 'Coin',
  TigerGaming: 'Chico',
  BetOnline: 'Chico',
  SportsBetting: 'Chico',
  'WPT Global': 'WPT',
  // prettier-ignore
  'ПокерOK': 'GG',
  GGPoker: 'GG',
  BetKings: 'GG',
  OlyBet: 'GG',
  Natural8: 'GG',

  'Ya Poker': 'WPN',
  ACR: 'WPN',
  PokerKing: 'WPN',
};
