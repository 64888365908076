import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Descriptions } from 'antd';
import { selectStatistics } from 'store/statistics';
import { getMyHold } from 'store/holds';
import { selectRates } from 'store/rates';
import Amount from 'components/Amount';

const Statistics = () => {
  const {
    availableForOutput,
    personalMoney,
    requiredForGameFromProject,
    requiredForGameFromPlayer,
    projectMoney,
    over,
  } = useSelector(selectStatistics);
  const { latest } = useSelector(selectRates);
  const dispatch = useDispatch();
  const [hold, setHold] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    // noinspection JSUnresolvedReference
    dispatch(getMyHold()).then((res) => setHold(res?.data));
  }, [dispatch]);

  const difference = useMemo(
    () => (latest?.ratio && hold?.amount && hold?.rate ? hold.amount * latest?.ratio - hold.amount * hold.rate : 0),
    [latest, hold],
  );

  return (
    <Descriptions bordered column={1} labelStyle={{ width: '50%' }} contentStyle={{ width: '50%' }}>
      {!!hold?.amount && (
        <>
          <Descriptions.Item label={t('onHold')}>
            <Amount amount={hold.amount} currency="EUR" secondary={{ amount: difference }} />
          </Descriptions.Item>
          <Descriptions.Item label={t('holdRate')}>{parseFloat(hold.rate).toFixed(6)} Av.R</Descriptions.Item>
        </>
      )}
      <Descriptions.Item label={t('personalMoney')}>{personalMoney.toFixed(2)} $</Descriptions.Item>
      <Descriptions.Item label={t('requiredForGameFromProject')}>
        {requiredForGameFromProject.toFixed(2)} $
      </Descriptions.Item>
      <Descriptions.Item label={t('requiredForGameFromPlayer')}>
        {requiredForGameFromPlayer.toFixed(2)} $
      </Descriptions.Item>
      <Descriptions.Item label={t('availableForOutput')}>{availableForOutput.toFixed(2)} $</Descriptions.Item>
      <Descriptions.Item label={t('projectMoney')}>{projectMoney.toFixed(2)} $</Descriptions.Item>
      <Descriptions.Item label={t('over')}>{over.toFixed(2)} $</Descriptions.Item>
    </Descriptions>
  );
};

export default Statistics;
