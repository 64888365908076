import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, Tag, Table, Space, Input, Select } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import debounce from 'lodash.debounce';
import { stopPropagation } from 'utils/common';
import { useTableParams } from 'hooks/useTableParams';
import { permissions } from 'config/permissions';
import { can } from 'store/auth';
import { getAll, selectList } from 'store/users';
import { InfiniteScroll } from 'components/InfiniteScroll';
import { RolesSelect } from 'components/Form/RolesSelect';
import StatusCell from './fragments/StatusCell';
import ActionsCell from './fragments/ActionsCell';
import CreateModal from './fragments/CreateModal/CreateModal';
import CreateAdminModal from './fragments/CreateAdminModal/CreateAdminModal';

const statuses = [
  { label: 'Active', value: 'active' },
  { label: 'Blocked', value: 'blocked' },
];

const tableParams = {
  pagination: {
    page: 1,
    pageSize: 40,
  },
};

const downloadTimezones = () => window.open(`${process.env.REACT_APP_BASE_URL}/users/export-timezones`);

export const Users = () => {
  const dispatch = useDispatch();
  const { list, loading } = useSelector(selectList);
  const hasPermission = useSelector(can);
  const { params, addFilter, nextPage, handleTableChange } = useTableParams(tableParams);

  const fetchUsers = useCallback(() => dispatch(getAll(params)), [dispatch, params]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const columns = useMemo(
    () => [
      {
        title: <Input placeholder="Login" onClick={stopPropagation} onChange={debounce(addFilter('name'), 500)} />,
        dataIndex: 'username',
        key: 'username',
        sorter: true,
        fixed: true,
      },
      {
        title: <Input placeholder="E-mail" onClick={stopPropagation} onChange={debounce(addFilter('email'), 500)} />,
        dataIndex: 'email',
        key: 'email',
        sorter: true,
      },
      {
        title: <RolesSelect allowClear placeholder="Role" style={{ width: '100%' }} onChange={addFilter('role')} />,
        dataIndex: 'roles',
        key: 'role',
        render: (roles) => {
          if (!roles?.length) {
            return '-';
          }

          let color = 'geekblue';
          let name = 'User';
          if (roles.some((role) => role.name === 'admin')) {
            color = 'volcano';
            name = 'Admin';
          }

          return <Tag color={color}>{name}</Tag>;
        },
      },
      {
        title: (
          <Select
            allowClear
            virtual={false}
            placeholder="Status"
            style={{ width: '100%' }}
            onChange={addFilter('status')}
            options={statuses}
          />
        ),
        dataIndex: 'blockedAt',
        key: 'status',
        render: (blockedAt) => <StatusCell blockedAt={blockedAt} />,
      },
      {
        title: 'Registered at',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        render: (createdAt) => new Date(createdAt).toLocaleDateString('en-GB'),
      },
      {
        title: 'Actions',
        dataIndex: '_id',
        key: 'actions',
        align: 'center',
        render: (id, user) => <ActionsCell id={id} user={user} onChange={fetchUsers} />,
      },
    ],
    [addFilter, fetchUsers],
  );

  return (
    <>
      <Space>
        {hasPermission(permissions.CanManageUsers) && <CreateModal />}
        {hasPermission(permissions.CanManageAdmins) && <CreateAdminModal />}
        <Button type="primary" icon={<DownloadOutlined />} onClick={downloadTimezones}>
          Download last timezones
        </Button>
      </Space>
      <Divider />
      <InfiniteScroll dataLength={list.docs.length} hasMore={list.hasNextPage} next={nextPage}>
        <Table
          loading={loading}
          rowKey="_id"
          pagination={false}
          onChange={handleTableChange}
          columns={columns}
          dataSource={list.docs}
        />
      </InfiniteScroll>
    </>
  );
};

export default Users;
