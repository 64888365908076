import { useCallback, useMemo, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, Modal, notification, Row, Select, Switch } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import debounce from 'lodash.debounce';
import { mapFormErrors } from 'utils/format';
import { createAccount } from 'store/accounts';
import { CurrencySelect } from 'components/Form/CurrencySelect';
import CountrySelect from 'components/CountrySelect';
import { ACCOUNT_CONFIGURATION, INITIAL_STATE, INITIAL_VALUES, UNIQUE_VALIDATOR_MESSAGE } from './config';
import { uniqueFields } from './utils';
import { useAccountModal } from './hooks';

export const CreateModal = ({ balance, onCreateAccount, createdAccounts }) => {
  const {
    form,
    dispatch,
    visible,
    setVisible,
    currentType,
    setCurrentType,
    ableToTransfer,
    transferAddressVisible,
    setIsAbleToTransfer,
    changeVisible,
    loading,
  } = useAccountModal(createdAccounts);
  const [rooms, setRooms] = useState([]);

  const purses = useMemo(() => ACCOUNT_CONFIGURATION.filter(({ type }) => type === 'purse'), []);

  const networks = useMemo(() => ACCOUNT_CONFIGURATION.filter(({ type }) => type === 'network'), []);

  const checkUniqueness = useCallback(() => {
    const { type, room, currency } = form.getFieldsValue(['type', 'room', 'currency']);

    const accountType = room ? room : type;

    if (
      accountType &&
      currency &&
      createdAccounts.some((created) => created.type === accountType && created.currency === currency)
    ) {
      form.setFields(uniqueFields([UNIQUE_VALIDATOR_MESSAGE]));
    } else {
      form.setFields(uniqueFields([]));
    }
  }, [form, createdAccounts]);

  const changeType = useCallback(
    (key) => {
      const account = ACCOUNT_CONFIGURATION.find((item) => item.key === key);
      if (account.type === 'network') {
        setRooms(account.rooms);
      } else {
        setCurrentType(account);
        setRooms([]);
      }
      form.resetFields(Object.keys(form.getFieldsValue()).filter((field) => field !== 'type'));
      setIsAbleToTransfer(false);
    },
    [form],
  );

  const changeRoom = useCallback(
    (key) => {
      setCurrentType(rooms.find((item) => item.key === key));
      form.resetFields(Object.keys(form.getFieldsValue()).filter((field) => !['type', 'room'].includes(field)));
      setIsAbleToTransfer(false);
    },
    [form, rooms],
  );

  const closeModal = useCallback(() => {
    setVisible(false);
    form.resetFields();
    setCurrentType(INITIAL_STATE);
    setIsAbleToTransfer(false);
  }, [form]);

  const onCreate = useCallback(
    async (data) => {
      checkUniqueness();
      if (form.getFieldsError().some(({ errors }) => errors.length)) {
        return;
      }

      try {
        const response = await dispatch(
          createAccount({
            ...data,
            type: currentType.name,
            balance: balance !== undefined ? balance : data.balance,
            groupId: currentType.groupId,
            region: data.region !== undefined ? currentType.regions[+data.region].value : undefined,
            transferAddress: currentType.transfer.equalToScreenName ? data.screenName : data.transferAddress,
          }),
        );
        // noinspection JSUnresolvedVariable
        onCreateAccount(response);
        closeModal();
      } catch (e) {
        if (e.response?.data?.errors) {
          form.setFields(mapFormErrors(e));
        }
        if (e.response?.data?.message) {
          notification.error({ message: e.response?.data?.message });
        }
      }
    },
    [checkUniqueness, currentType],
  );

  const onModalOk = useCallback((values) => form.submit(values), [form]);

  return (
    <>
      <Button type="primary" onClick={changeVisible(true)} icon={<PlusOutlined />}>
        Create
      </Button>
      <Modal open={visible} title="Create Account" onOk={onModalOk} onCancel={closeModal} confirmLoading={loading}>
        <Form
          name="create-account"
          form={form}
          layout="vertical"
          onFinish={debounce(onCreate)}
          initialValues={{ ...INITIAL_VALUES, balance }}
        >
          <Row gutter={10}>
            <Col span={20}>
              <Form.Item label="Type" name="type" rules={[{ required: true }]}>
                <Select onChange={changeType} virtual={false}>
                  <Select.OptGroup label="E-WALLET">
                    {purses.map(({ key, name }) => (
                      <Select.Option key={key}>{name}</Select.Option>
                    ))}
                  </Select.OptGroup>
                  <Select.OptGroup label="NETWORK">
                    {networks.map(({ key, name }) => (
                      <Select.Option key={key}>{name}</Select.Option>
                    ))}
                  </Select.OptGroup>
                </Select>
              </Form.Item>
              {!!rooms.length && (
                <Form.Item label="Room" name="room" rules={[{ required: true }]}>
                  <Select onChange={changeRoom} virtual={false}>
                    {rooms.map(({ key, name }) => (
                      <Select.Option key={key}>{name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
              {currentType.type === 'room' && (
                <>
                  <Form.Item label="Screen Name" name="screenName" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                  {currentType.multipleScreenNames && (
                    <Form.List name="otherScreenNames">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field, i) => (
                            <Form.Item {...field} label={i === 0 && 'Other Screen Names'}>
                              <Input addonAfter={<MinusOutlined onClick={() => remove(field.name)} />} />
                            </Form.Item>
                          ))}
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                            style={{ marginBottom: 24 }}
                          >
                            Add other screen names
                          </Button>
                        </>
                      )}
                    </Form.List>
                  )}
                </>
              )}
              <Form.Item label="Balance" name="balance" rules={[{ required: true }]}>
                <InputNumber
                  controls={false}
                  min={0}
                  disabled={balance !== undefined}
                  addonAfter={
                    <Form.Item noStyle name="currency" rules={[{ required: true }]}>
                      <CurrencySelect currencies={currentType.currencies} onChange={checkUniqueness} />
                    </Form.Item>
                  }
                  parser={(value) => value.replace(',', '.')}
                />
              </Form.Item>
              {currentType.transfer.available && transferAddressVisible && (
                <Form.Item
                  label="Transfer address"
                  name="transferAddress"
                  rules={[{ required: ableToTransfer, type: currentType.transfer.validate }]}
                >
                  <Input type={currentType.transfer.type} disabled={!ableToTransfer} />
                </Form.Item>
              )}
            </Col>
            <Col span={4}>
              <Form.Item label="Active" name="active" rules={[{ required: true }]}>
                <Switch defaultChecked={true} />
              </Form.Item>
              {currentType.regions.length === 2 && (
                <Form.Item label="Region" name="region" rules={[{ required: true }]}>
                  <CountrySelect />
                </Form.Item>
              )}
              {currentType.transfer.available && (
                <Form.Item label="Transfer" name="ableToTransfer" rules={[{ required: true }]}>
                  <Switch onChange={setIsAbleToTransfer} />
                </Form.Item>
              )}
              {currentType.type === 'purse' && (
                <Form.Item label="VIP" name="vip" rules={[{ required: true }]} style={{ marginBottom: 0 }}>
                  <Switch />
                </Form.Item>
              )}
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
