import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import ReactQuill, { Quill } from 'react-quill';
import ImageUploader from 'quill-image-uploader';
import 'react-quill/dist/quill.snow.css';
import 'quill-image-uploader/dist/quill.imageUploader.min.css';
import { uploadImage } from 'store/images';

Quill.register('modules/imageUploader', ImageUploader);

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'indent',
  'link',
  'image',
  'imageBlot',
];

const style = { height: 325, marginBottom: 20 };

const Editor = ({ value, onChange }) => {
  const handleChange = useCallback((val) => onChange(val), [onChange]);
  const dispatch = useDispatch();

  const modules = useMemo(
    () => ({
      toolbar: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link', 'image'],
        ['clean'],
      ],
      imageUploader: {
        upload: async (file) => {
          try {
            const formData = new FormData();
            formData.append('image', file, file.name);
            formData.append('packageId', 'notifications');
            formData.append('type', `${file.name}-${Date.now()}`);
            formData.append('currency', '');

            const data = await dispatch(uploadImage(formData));
            return data.url;
          } catch (e) {
            console.dir(e);
          }
        },
      },
    }),
    [dispatch],
  );

  return (
    <ReactQuill
      theme="snow"
      modules={modules}
      formats={formats}
      defaultValue={value}
      onChange={handleChange}
      style={style}
    />
  );
};

export default Editor;
