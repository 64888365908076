import { Button, Modal } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useToggle } from 'hooks/useToggle';

const ReviewsModal = ({ notification }) => {
  const { active, activate, deactivate } = useToggle();

  return (
    <>
      <Button title="View" type="primary" icon={<EyeOutlined />} onClick={activate} />
      <Modal title={notification.title} open={active} onCancel={deactivate} footer={null}>
        <div style={{ overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: notification?.description }} />
      </Modal>
    </>
  );
};

export default ReviewsModal;
