import { useCallback, useEffect } from 'react';
import { Button, Col, Form, Input, InputNumber, Modal, Row, Switch } from 'antd';
import { PlusOutlined, MinusOutlined, EditOutlined } from '@ant-design/icons';
import { mapFormErrors } from 'utils/format';
import { updateAccount } from 'store/accounts';
import { CurrencySelect } from 'components/Form/CurrencySelect';
import { ACCOUNT_CONFIGURATION, UNIQUE_VALIDATOR_MESSAGE } from './config';
import { useAccountModal } from './hooks';
import { uniqueFields } from './utils';

export const UpdateModal = ({ account, createdAccounts, onUpdateAccount, allowBalanceUpdate }) => {
  const {
    form,
    dispatch,
    visible,
    setVisible,
    currentType,
    setCurrentType,
    ableToTransfer,
    transferAddressVisible,
    setIsAbleToTransfer,
    changeVisible,
    loading,
  } = useAccountModal(createdAccounts);

  useEffect(() => {
    if (account && visible) {
      let type;
      for (const config of ACCOUNT_CONFIGURATION) {
        if (config.name === account.type && config.type === 'purse') {
          type = config;
          break;
        }

        if (config.rooms) {
          const room = config.rooms.find((r) => r.name === account.type);
          if (room) {
            type = room;
            break;
          }
        }
      }

      setCurrentType(type);
      setIsAbleToTransfer(account.ableToTransfer);
      form.setFieldsValue({
        ...account,
        vip: !!account.vip,
        region: account.region ? !!type?.regions.findIndex((region) => region.value === account.region) : undefined,
      });
    }
  }, [account, form, visible]);

  const checkUniqueness = useCallback(() => {
    const currency = form.getFieldValue('currency');

    if (currency && createdAccounts.some((created) => created.type === account.type && created.currency === currency)) {
      form.setFields(uniqueFields([UNIQUE_VALIDATOR_MESSAGE]));
    } else {
      form.setFields(uniqueFields([]));
    }
  }, [form, account, createdAccounts]);

  const onUpdate = useCallback(
    async (data) => {
      try {
        const response = await dispatch(
          updateAccount(account._id, {
            ...data,
            region: data.region !== undefined ? currentType.regions[+data.region].value : undefined,
            transferAddress: currentType.transfer.equalToScreenName ? data.screenName : data.transferAddress,
          }),
        );
        // noinspection JSUnresolvedVariable
        onUpdateAccount(response);
        setVisible(false);
      } catch (e) {
        if (e.response?.data?.errors) {
          form.setFields(mapFormErrors(e));
        }
      }
    },
    [account, form, currentType],
  );

  return (
    <>
      <Button type="primary" onClick={changeVisible(true)} icon={<EditOutlined />} />
      <Modal
        open={visible}
        title="Update Account"
        onOk={form.submit}
        onCancel={changeVisible(false)}
        confirmLoading={loading}
      >
        <Form name="update-account" form={form} layout="vertical" onFinish={onUpdate}>
          <Row gutter={10}>
            <Col span={20}>
              {currentType.type === 'room' && (
                <>
                  <Form.Item label="Screen Name" name="screenName" rules={[{ required: true }]}>
                    <Input />
                  </Form.Item>
                  {currentType.multipleScreenNames && (
                    <Form.List name="otherScreenNames">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field, i) => (
                            <Form.Item {...field} label={i === 0 && 'Other Screen Names'}>
                              <Input addonAfter={<MinusOutlined onClick={() => remove(field.name)} />} />
                            </Form.Item>
                          ))}
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                            style={{ marginBottom: 24 }}
                          >
                            Add other screen names
                          </Button>
                        </>
                      )}
                    </Form.List>
                  )}
                </>
              )}
              <Form.Item label="Balance" name="balance" rules={[{ required: true }]}>
                <InputNumber
                  controls={false}
                  min={0}
                  disabled={!allowBalanceUpdate}
                  addonAfter={
                    <Form.Item noStyle name="currency" rules={[{ required: true }]}>
                      <CurrencySelect
                        disabled={account.user}
                        currencies={currentType.currencies}
                        onChange={checkUniqueness}
                      />
                    </Form.Item>
                  }
                  parser={(value) => value.replace(',', '.')}
                />
              </Form.Item>
              {currentType.transfer.available && transferAddressVisible && (
                <Form.Item
                  label="Transfer address"
                  name="transferAddress"
                  rules={[{ required: ableToTransfer, type: currentType.transfer.validate }]}
                >
                  <Input type={currentType.transfer.type} disabled={!ableToTransfer} />
                </Form.Item>
              )}
            </Col>
            <Col span={4}>
              <Form.Item label="Active" name="active" rules={[{ required: true }]}>
                <Switch defaultChecked={account.active} />
              </Form.Item>
              {currentType.regions.length > 0 && (
                <Form.Item label="Region" name="region" rules={[{ required: true }]}>
                  <Switch
                    unCheckedChildren={currentType.regions[0].label}
                    checkedChildren={currentType.regions[1].value}
                    defaultChecked={account.region === currentType.regions[1].value}
                  />
                </Form.Item>
              )}
              {currentType.transfer?.available && (
                <Form.Item label="Transfer" name="ableToTransfer" rules={[{ required: true }]}>
                  <Switch defaultChecked={account.ableToTransfer} onChange={setIsAbleToTransfer} />
                </Form.Item>
              )}
              {currentType.type === 'purse' && (
                <Form.Item label="VIP" name="vip" rules={[{ required: true }]} style={{ marginBottom: 0 }}>
                  <Switch defaultChecked={!!account.vip} />
                </Form.Item>
              )}
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
