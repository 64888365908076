// noinspection JSCheckFunctionSignatures

import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { apiCallBegan } from './api';

const slice = createSlice({
  name: 'totalImages',
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {
    totalImagesRequestStarted: (totalImages) => {
      totalImages.loading = true;
    },
    totalImagesRequestFailed: (totalImages, action) => {
      totalImages.error = action.payload.data;
    },
    totalImagesRequestEnded: (totalImages) => {
      totalImages.loading = false;
    },
    totalImagesListReceived: (totalImages, action) => {
      totalImages.list = action.payload.data;
    },
  },
});

export const { totalImagesRequestStarted, totalImagesRequestFailed, totalImagesRequestEnded, totalImagesListReceived } =
  slice.actions;
export default slice.reducer;

export const importTotalImages = (data) =>
  apiCallBegan({
    url: `/total-images`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    method: 'POST',
    data,
    onStart: totalImagesRequestStarted,
    onEnd: totalImagesRequestEnded,
  });

//selector
export const selectList = createSelector(
  (state) => state.totalImages,
  (totalImages) => ({
    loading: totalImages.loading,
  }),
);
