import { Select } from 'antd';
import { ACCOUNT_REGIONS } from 'config/accounts';

const accountOptions = Object.keys(ACCOUNT_REGIONS).map((key) => ({
  value: key,
  label: ACCOUNT_REGIONS[key].label,
}));

const AccountSelect = (props) => <Select options={accountOptions} style={{ width: '100%' }} {...props} />;

export default AccountSelect;
