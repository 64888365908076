export const TYPES = [
  {
    value: 1,
    label: 'pko regspeed',
  },
  {
    value: 2,
    label: '8-9 max non-pko regspeed',
  },
  {
    value: 3,
    label: '5-7 max non-pko regspeed',
  },
  {
    value: 4,
    label: '5-7 max pko turbo',
  },
  {
    value: 5,
    label: '5-7 max pko hyper',
  },
  {
    value: 6,
    label: '8-9 max pko turbo',
  },
  {
    value: 7,
    label: '5-7 max non-pko turbo/hyper',
  },
  {
    value: 8,
    label: '8-9 max non-pko turbo/hyper',
  },
  {
    value: 9,
    label: '2-4 max all type',
  },
];

export const MY_ROOMS = 'my_rooms';
