import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';
import { createSelector } from 'reselect';

const slice = createSlice({
  name: 'preferences',
  initialState: {
    data: null,
    loading: false,
    error: null,
  },
  reducers: {
    preferencesRequestStarted: (preferences) => {
      preferences.loading = true;
    },
    preferencesRequestFailed: (preferences, action) => {
      preferences.error = action.payload.data;
    },
    preferencesRequestEnded: (preferences) => {
      preferences.loading = false;
    },
    preferencesReceived: (preferences, action) => {
      preferences.data = action.payload.data;
    },
  },
});

export const { preferencesRequestStarted, preferencesRequestFailed, preferencesRequestEnded, preferencesReceived } =
  slice.actions;
export default slice.reducer;

const BASE_URL = '/preferences';

export const getPreferences = () =>
  apiCallBegan({
    url: BASE_URL,
    onStart: preferencesRequestStarted,
    onSuccess: preferencesReceived,
    onError: preferencesRequestFailed,
    onEnd: preferencesRequestEnded,
  });

export const setTournamentRooms = (rooms) =>
  apiCallBegan({
    url: `${BASE_URL}/tournament-rooms`,
    method: 'put',
    data: { rooms },
    onStart: preferencesRequestStarted,
    onSuccess: preferencesReceived,
    onEnd: preferencesRequestEnded,
  });

export const selectPreferences = createSelector(
  (state) => state.preferences,
  (preferences) => preferences.data,
);
