import { useMemo } from 'react';
import { Button, Popconfirm, Space, Tag } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import SmartTable from 'components/Table/SmartTable';
import CountrySelect from 'components/CountrySelect';
import AccountSelect from '../components/AccountSelect';
import UpdateConfigModal from './UpdateConfigModal';

const List = ({ isLoading, dataSource, onNextPage, onAddFilter, onUpdate, onRemove }) => {
  const columns = useMemo(
    () => [
      {
        key: 'type',
        title: <AccountSelect placeholder="Account" allowClear onChange={onAddFilter('type')} />,
        dataIndex: 'type',
      },
      {
        key: 'countryFrom',
        title: <CountrySelect placeholder="Country From" allowClear onChange={onAddFilter('countryFrom')} />,
        dataIndex: 'countryFrom',
      },
      {
        key: 'statusesFrom',
        title: 'Statuses From',
        dataIndex: 'statusesFrom',
        render: (statusesFrom) => (
          <Space>
            {statusesFrom.map((status) => (
              <Tag key={status} color="geekblue">
                {status}
              </Tag>
            ))}
          </Space>
        ),
      },
      {
        key: 'countryTo',
        title: <CountrySelect placeholder="Country To" allowClear onChange={onAddFilter('countryTo')} />,
        dataIndex: 'countryTo',
      },
      {
        key: 'statusesTo',
        title: 'Statuses To',
        dataIndex: 'statusesTo',
        render: (statusesTo) => (
          <Space>
            {statusesTo.map((status) => (
              <Tag key={status} color="geekblue">
                {status}
              </Tag>
            ))}
          </Space>
        ),
      },
      {
        key: 'actions',
        dataIndex: '_id',
        title: '',
        render: (id, config) => (
          <Space>
            <UpdateConfigModal config={config} onClose={onUpdate} />
            <Popconfirm title="Are you sure you want to delete this setting?" onConfirm={() => onRemove(id)}>
              <Button danger type="primary" icon={<DeleteOutlined />} />
            </Popconfirm>
          </Space>
        ),
      },
    ],
    [onAddFilter, onUpdate, onRemove],
  );

  return (
    <SmartTable
      rowKey="_id"
      loading={isLoading}
      columns={columns}
      dataSource={dataSource}
      className="account-regions-table"
      scroll={{ y: 'calc(100vh - 190px)' }}
      infinity
      onFetch={onNextPage}
    />
  );
};

export default List;
