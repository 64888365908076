import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, Popconfirm, Space, Tag } from 'antd';
import { SendOutlined, DeleteOutlined, CopyOutlined, EditOutlined } from '@ant-design/icons';
import ModalController from 'components/Modal/ModalController';
import { useModal } from 'components/Modal';
import SmartTable from 'components/Table/SmartTable';
import { useTableParams } from 'hooks/useTableParams';
import { toDate } from 'utils/format';
import { getAll, activate, remove, duplicate, selectNotifications } from 'store/notifications';
import { Status } from './config';
import CreateNotificationModal from './fragments/CreateNotificationModal';
import UserReviewsModal from './fragments/UserReviewsModal';
import ViewNotificationModal from './fragments/ViewNotificationModal';
import EditNotificationModal, { MODAL_ID } from './fragments/EditNotificationModal';

const Notifications = () => {
  const dispatch = useDispatch();
  const { params, nextPage } = useTableParams();
  const notifications = useSelector(selectNotifications);
  const { activate: openModal } = useModal();

  const fetchNotifications = useCallback(() => dispatch(getAll(params)), [dispatch, params]);

  const openEditModal = useCallback(
    (data) => {
      openModal(MODAL_ID, { notification: data });
    },
    [openModal],
  );

  const resend = useCallback(
    async (id) => {
      await dispatch(activate(id));
      fetchNotifications();
    },
    [dispatch, fetchNotifications],
  );

  const removeOne = useCallback(
    async (id) => {
      await dispatch(remove(id));
      fetchNotifications();
    },
    [dispatch, fetchNotifications],
  );

  const duplicateOne = useCallback(
    async (id) => {
      const data = await dispatch(duplicate(id));
      fetchNotifications();
      openEditModal(data);
    },
    [dispatch, fetchNotifications],
  );

  useEffect(() => {
    fetchNotifications();
  }, [fetchNotifications]);

  const columns = useMemo(
    () => [
      { key: 'title', dataIndex: 'title', title: 'Title' },
      {
        key: 'status',
        dataIndex: 'status',
        title: 'Status',
        render: (status) => {
          const color = status === Status.Pending ? 'orange' : 'green';

          return <Tag color={color}>{status}</Tag>;
        },
      },
      { key: 'createdAt', dataIndex: 'createdAt', title: 'Created At', render: toDate },
      {
        key: 'filter',
        dataIndex: 'filter',
        title: 'Course',
        render: (filter) => {
          if (!filter) {
            return 'All';
          }

          return `Min: ${filter.course?.min || 1}, Max: ${filter.course?.max || 15}`;
        },
      },
      {
        key: 'actions',
        dataIndex: '_id',
        width: 170,
        render: (id, model) => (
          <Space>
            <Popconfirm title="Are you sure?" onConfirm={() => resend(id)}>
              <Button type="primary" icon={<SendOutlined />} title="Send" />
            </Popconfirm>
            <Popconfirm title="Are you sure?" onConfirm={() => duplicateOne(id)}>
              <Button type="primary" icon={<CopyOutlined />} title="Duplicate" />
            </Popconfirm>
            {model.status === Status.Pending ? (
              <>
                <Button icon={<EditOutlined />} type="primary" onClick={() => openEditModal(model)} />
                <Popconfirm title="Are you sure?" onConfirm={() => removeOne(id)}>
                  <Button type="primary" icon={<DeleteOutlined />} title="Remove" />
                </Popconfirm>
              </>
            ) : (
              <ViewNotificationModal notification={model} />
            )}
          </Space>
        ),
      },
    ],
    [resend, duplicateOne, removeOne],
  );

  return (
    <>
      <Space>
        <CreateNotificationModal />
        <UserReviewsModal />
      </Space>
      <Divider />
      <SmartTable
        rowKey="_id"
        columns={columns}
        dataSource={notifications.docs}
        scroll={{ y: 'calc(100vh - 230px)' }}
        infinity
        onFetch={nextPage}
      />
      <ModalController id={MODAL_ID}>
        <EditNotificationModal />
      </ModalController>
    </>
  );
};

export default Notifications;
