import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Popconfirm } from 'antd';
import { getMyActive, review } from 'store/notifications';

const Notification = () => {
  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);
  const [activeNotification, setActiveNotification] = useState(undefined);
  const { t } = useTranslation();

  const getNotifications = useCallback(async () => {
    try {
      const data = await dispatch(getMyActive());
      const [active, ...rest] = data;
      setActiveNotification(active);
      setNotifications(rest);
    } catch (e) {}
  }, [dispatch]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  const dismiss = useCallback(() => {
    if (!activeNotification) {
      return;
    }

    dispatch(review(activeNotification._id));
    const [active, ...rest] = notifications;
    setNotifications(rest);
    setActiveNotification(active);
  }, [dispatch, activeNotification, notifications, getNotifications]);

  return (
    <Modal
      open={!!activeNotification}
      title={activeNotification?.title}
      width={1000}
      footer={
        <Popconfirm title={t('areYouSure')} onConfirm={dismiss}>
          <Button type="primary">{t('notifications.read')}</Button>
        </Popconfirm>
      }
      closable={false}
    >
      <div dangerouslySetInnerHTML={{ __html: activeNotification?.description }} />
    </Modal>
  );
};

export default Notification;
