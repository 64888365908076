import { toCurrency } from 'utils/format';
import { compare } from 'utils/account';
import { Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const EXCLUDED_FIELDS = [
  '_id',
  '__v',
  'user',
  'username',
  'manual',
  'createdAt',
  'packageProfit',
  'projectProfit',
  'sessionProfit',
  'projectSessionProfit',
  'personalMoney',
  'projectMoney',
];

export const getColumns = (data) => {
  if (!data.length) {
    return [];
  }

  const columns = [
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: 'Date',
      render: (date, model) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
          {new Date(date).toLocaleDateString('ru-RU')}
          {model.manual && (
            <Tooltip title="Created manually">
              <ExclamationCircleOutlined />
            </Tooltip>
          )}
        </div>
      ),
      fixed: true,
    },
    {
      key: 'username',
      dataIndex: 'username',
      title: 'Username',
    },
  ];

  Object.keys(data[data.length - 1])
    .filter((key) => !EXCLUDED_FIELDS.includes(key))
    .map((key) => {
      const titleChunks = key.split('_');
      const currency = titleChunks.pop();

      return {
        key,
        type: titleChunks.join('.'),
        currency,
      };
    })
    .sort(compare)
    .forEach(({ key, type, currency }) => {
      columns.push({
        key,
        dataIndex: key,
        title: `${type} ${toCurrency(currency)}`,
        render: (val) => (val || 0).toFixed(2),
      });
    });

  columns.push(
    {
      key: 'packageProfit',
      dataIndex: 'packageProfit',
      title: 'Package profit',
      width: 112,
      render: (val) => (val || val === 0 ? val.toFixed(2) : '-'),
    },
    {
      key: 'projectProfit',
      dataIndex: 'projectProfit',
      title: 'Project profit',
      width: 103,
      render: (val) => (val || val === 0 ? val.toFixed(2) : '-'),
    },
    {
      key: 'sessionProfit',
      dataIndex: 'sessionProfit',
      title: 'Session profit',
      width: 112,
      render: (val) => (val || val === 0 ? val.toFixed(2) : '-'),
    },
    {
      key: 'projectSessionProfit',
      dataIndex: 'projectSessionProfit',
      title: 'Project session profit',
      width: 157,
      render: (val) => (val || val === 0 ? val.toFixed(2) : '-'),
    },
  );

  return columns;
};
