export const getTextToCopy = (fullPackage) => {
  let text = '';
  if (fullPackage && fullPackage?.statistic && fullPackage?.approvePackageStatistic) {
    const beforeApprove = fullPackage.statistic;
    const afterApprove = fullPackage.approvePackageStatistic;
    text = text.concat('Расчет: \n\n');
    if (beforeApprove.playerProfit !== undefined) {
      text = text.concat(`Профит игрока = $${beforeApprove.playerProfit?.toFixed(2)} \n`);
    }
    if (beforeApprove.projectProfit !== undefined) {
      text = text.concat(`Профит фонда = $${beforeApprove.projectProfit?.toFixed(2)} \n`);
    }
    if (afterApprove.personalMoney !== undefined) {
      text = text.concat(`Личные деньги игрока = $${afterApprove.personalMoney?.toFixed(2)} `);
      if (afterApprove.currentHold !== undefined && afterApprove.currentHold !== 0) {
        text = text.concat(`(+ €${afterApprove.currentHold?.toFixed(2)})`);
      }
      text = text.concat(`\n`);
    }

    if (afterApprove.availableForOutput !== undefined) {
      text = text.concat(`Доступно к выводу = $${afterApprove.availableForOutput?.toFixed(2)} `);
      if (afterApprove.currentHold !== undefined && afterApprove.currentHold !== 0) {
        text = text.concat(`(+ €${afterApprove.currentHold?.toFixed(2)})`);
      }
      text = text.concat(`\n`);
    }

    if (afterApprove.currentHold !== undefined && afterApprove.currentHold !== 0) {
      text = text.concat(`Hold = €${afterApprove.currentHold?.toFixed(2)} \n`);
    }

    if (afterApprove.nextPackageBuyInPercent !== undefined && afterApprove.nextPackageProfitPercent !== undefined) {
      text = text.concat(`\n\n`);
      text = text.concat(`Условия нового пакета: \n`);
      text = text.concat(`${afterApprove.nextPackageBuyInPercent}% - внесение от себя \n`);
      text = text.concat(`${afterApprove.nextPackageProfitPercent}% - доля прибыли пакета \n`);
    }

    if (afterApprove.wasOnFreeze && afterApprove.toUnFreeze !== undefined && afterApprove.currentFreeze !== undefined) {
      text = text.concat(`\n`);
      text = text.concat('======================\n');
      text = text.concat(
        `Мейк = -${afterApprove.currentFreeze.toFixed(2)} + ${afterApprove.toUnFreeze.toFixed(2)} = ${(
          -afterApprove.currentFreeze + afterApprove.toUnFreeze
        ).toFixed(2)} \n`,
      );
      text = text.concat('======================\n');
      if (afterApprove.make === 0) {
        text = text.concat(`Мейк погашен \n`);
      }
    }
  }

  return text;
};
