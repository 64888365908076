import { Select } from 'antd';
import { CountryRegionData } from 'react-country-region-selector';

const countryOptions = CountryRegionData.map((country) => ({
  value: country[0],
  label: country[0],
}));

const CountrySelect = (props) => <Select showSearch options={countryOptions} style={{ width: '100%' }} {...props} />;

export default CountrySelect;
