import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, Checkbox, Col, Row, Space } from 'antd';
import { getUserById, updatePermissions } from 'store/users';
import { UserSelect } from 'components/Form/UserSelect';
import { getPermissions } from 'store/rbac';
import CreatePermissionModal from './fragments/CreatePermissionModal';

const Permissions = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [value, setValue] = useState([]);
  const [permissions, setPermissions] = useState([]);

  const getUser = useCallback(
    async (id) => {
      const data = await dispatch(getUserById(id));
      setUser(data);
    },
    [dispatch],
  );

  useEffect(() => {
    setValue(user?.permissions || []);

    return () => setValue([]);
  }, [user]);

  const fetchPermissions = useCallback(async () => {
    const data = await dispatch(getPermissions());

    setPermissions(
      data.map((perm) => ({
        value: perm._id,
        label: perm.name,
      })),
    );
  }, [dispatch]);

  useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);

  const update = useCallback(() => {
    if (user) {
      dispatch(updatePermissions(user._id, value));
    }
  }, [dispatch, user, value]);

  return (
    <Card
      title={<UserSelect onSelect={getUser} filter={{ role: 'admin' }} />}
      extra={
        <Space>
          <Button type="primary" onClick={update}>
            Save
          </Button>
          <CreatePermissionModal onCreate={fetchPermissions} />
        </Space>
      }
    >
      <Checkbox.Group disabled={!user} value={value} onChange={setValue}>
        <Row>
          {permissions.map((option) => (
            <Col span={6} key={option.value}>
              <Checkbox value={option.value}>{option.label}</Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </Card>
  );
};

export default Permissions;
